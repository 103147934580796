export const getCloudinaryUrl = (asset: any, attrs: any, type = "image") => {
  let sanitizedAsset = null;

  if (asset) {
    if (asset.includes("res.cloudinary")) {
      const substr = asset.substring(asset.indexOf("v1"));
      sanitizedAsset = substr.replace(substr.split("/")[0], "");
    } else {
      sanitizedAsset = asset;
    }
  }

  return sanitizedAsset
    ? `https://res.cloudinary.com/de4rvmslk/${type}/upload/${attrs}/${sanitizedAsset}`
    : "";
};
