import React from "react";
import { kebabCase } from "lodash";
import { useWindowWidth } from "hooks";
import "./FooterSection.scss";
import { data } from "./data";
import Paragraph from "components/Paragraph";
import { getCloudinaryUrl } from "helpers";
import { config } from "config";
import IconSVG from "components/IconSVG/IconSVG";
import { JobOffer } from "models";

type FooterSectionProps = {
  jobs: JobOffer[];
};
export const FooterSection = ({ jobs }: FooterSectionProps) => {
  const renderPlaces = () =>
    data.map((item) => (
      <div key={item.title} className={"footer__info-place"}>
        <Paragraph
          size={"medium"}
          additionalClass={"footer__info-place--hq text--bold"}
        >
          {item.title}
        </Paragraph>
        <Paragraph additionalClass={"footer__info-address"}>
          <a href={item.map} className={"text--light"}>
            <span id={`footer-map-${kebabCase(item.city.toLowerCase())}`}>
              {item.street}
              <br />
            </span>
          </a>
          <span className={"text--light"}>
            {item.postal}&nbsp;{item.city}
          </span>
          <br />
          <span className={"text--light"}>{item.country}</span>
        </Paragraph>
      </div>
    ));

  const isTabletBig = useWindowWidth() >= 992;

  return (
    <footer className={"forThePlanet__footer"}>
      <div className={"forThePlanet__footer--top"}>
        <div className={"forThePlanet__footer--column"}>
          <a href={"/"} className={"footer__top-logo"}>
            <img
              src={getCloudinaryUrl(
                "remote-work-landing/boldare-logo.svg",
                "f_auto,q_auto,w_72"
              )}
              className={"footer__top-bldr"}
              alt={"Boldare logo"}
              loading={"lazy"}
            />
          </a>

          <div className={"forThePlanet__footer__top--links"}>
            <div className={"forThePlanet__footer__top-sm"}>
              <a
                className={"link"}
                href={config.facebook}
                id={"footer-facebook"}
                rel={"nofollow"}
              >
                <IconSVG
                  name={"facebook-letter"}
                  size={"medium"}
                  color={"black"}
                />
              </a>
              <a
                className={"link"}
                href={config.linkedin}
                id={"footer-linkedin"}
                rel={"nofollow"}
              >
                <IconSVG
                  name={"linkedin-letter"}
                  size={"medium"}
                  color={"black"}
                />
              </a>
              <a
                className={"link"}
                href={config.instagram}
                id={"footer-instagram"}
                rel={"nofollow"}
              >
                <IconSVG name={"instagram"} size={"medium"} color={"black"} />
              </a>
            </div>
            <div className={"forThePlanet__footer__menu-items"}>
              <a
                href={"https://boldare.com/privacy-policy/"}
                id={"forThePlanet-cookies"}
                className={"link text--light"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                <span className={"footer__cookies"}>Cookies</span>
              </a>
              <a
                href={"https://boldare.com/privacy-policy/"}
                id={"forThePlanet-terms"}
                className={"link text--light"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                <span className={"footer__cookies"}>Terms & Policy</span>
              </a>
            </div>
          </div>
        </div>

        <div className={"forThePlanet__footer--column"}>
          <Paragraph
            size={"medium"}
            additionalClass={"footer__info-jobs--hq text--bold"}
          >
            Jobs
          </Paragraph>
          <div className={"forThePlanet__jobs-list"}>
            {jobs.map((item) => (
              <a
                key={item.node.id}
                className={`link text--light career-footer-link ga-products--footer-${item.node.frontmatter.mainContent.name.replace(
                  / /g,
                  "-"
                )}`}
                href={item.node.frontmatter.mainContent.apply}
                rel={"nofollow"}
              >
                {item.node.frontmatter.mainContent.name}
              </a>
            ))}
          </div>
        </div>

        <div className={"forThePlanet__footer--column"}>{renderPlaces()}</div>
      </div>
      <div className={"forThePlanet__footer--bottom text--light"}>
        <p className={"footer__copyright"}>
          Boldare For The Planet <span>{new Date().getFullYear()}&copy;</span>
        </p>
        {isTabletBig && (
          <div className={"footer__navigation"}>
            <button
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Go back to the top &#8593;
            </button>
          </div>
        )}
        <a
          href="https://www.boldare.com/career/"
          className={"footer__copyright career-link"}
        >
          &#8592; Go to career
        </a>
      </div>
    </footer>
  );
};
