import React from "react";
import { classNames } from "helpers";
import "./Heading.scss";

type HeadingProps = {
  additionalClass?: string;
  afterline?: boolean;
  decorator?: string;
  size?: string | number;
  color?: string;
  type?: 1 | 2 | 3 | 4 | 5 | 6 | null;
  children?: React.ReactNode;
  bgColor?: string;
  title?: string;
};

const Heading = React.forwardRef(
  (
    {
      additionalClass = "",
      afterline = false,
      children,
      decorator = "",
      type = 3,
      color = "",
      size = "default",
      bgColor = "",
      title = "",
    }: HeadingProps,
    ref
  ) => {
    const HeadingTag: any = `h${type}`;

    return (
      <HeadingTag
        title={title}
        ref={ref}
        className={classNames(
          "heading",
          {
            [`heading--${size}`]: size,
            [`heading__${decorator}`]: decorator,
            [`heading__fill--${bgColor}`]: bgColor,
            [`text--${color}`]: color,
            [`heading__afterline heading__afterline--${color}`]: afterline,
          },
          additionalClass
        )}
      >
        {children}
      </HeadingTag>
    );
  }
);

export default Heading;
