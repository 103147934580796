export const data = [
  {
    title: "HQ Gliwice",
    map: "https://www.google.com/maps/search/?api=1&query=Królowej+Bony+13,+41-100+Gliwice",
    city: "Gliwice",
    street: "Królowej Bony 13",
    postal: "44-100",
    country: "Poland",
  },
  {
    title: "Warsaw",
    map: "https://www.google.com/maps/search/?api=1&query=Krucza+50,+00-025+Warszawa",
    city: "Warsaw",
    street: "Krucza 50",
    postal: "00-025",
    country: "Poland",
  },
  {
    title: "Wroclaw",
    map: "https://www.google.com/maps/search/?api=1&query=Wyspa+Slodowa+7,+50-266+Wroclaw",
    city: "Wroclaw",
    street: "Wyspa Słodowa 7",
    postal: "50-266",
    country: "Poland",
  },
  {
    title: "Cracow",
    map: "https://www.google.com/maps/search/?api=1&query=Pawia+9,+31-154+Cracow",
    city: "Cracow",
    street: "Pawia 9",
    postal: "31-154",
    country: "Poland",
  },
];

export const paths = [
  {
    name: "Services",
    link: "/services/",
    children: [
      {
        link: "/services/software-development-outsourcing/",
        name: "Software Development Outsourcing",
      },
      {
        link: "/services/full-cycle-product-development/",
        name: "Full Cycle Product Development",
      },
      {
        link: "/services/code-audit/",
        name: "Code audit & UX audit",
      },
      {
        link: "/services/full-cycle-product-development/prototyping/",
        name: "Product Prototyping",
      },
      {
        link: "/services/mvp-development/",
        name: "Minimum Viable Product",
      },
      {
        link: "/services/product-market-fit-expanding-demand/",
        name: "Product-Market Fit",
      },
      {
        link: "/services/scaling-your-product-thriving-in-the-market/",
        name: "Product Scaling",
      },
      {
        link: "/services/product-design-and-development/",
        name: "Product Design and Development",
      },
      {
        link: "/services/development-teams/",
        name: "Development Teams",
      },
      {
        link: "/services/machine-learning/",
        name: "Machine Learning Services",
      },
      {
        link: "/services/digital-transformation/",
        name: "Digital Transformation",
      },
    ],
  },
  {
    name: "About us",
    link: "/about/",
  },
  {
    name: "Resources",
    link: "/resources/",
  },
  {
    name: "New Normal",
    link: "/new-normal/",
  },
  {
    name: "Contact",
    link: "/contact/",
  },
  {
    name: "Career",
    link: "/career/",
  },
  {
    name: "Blog",
    link: "/blog/",
  },
  {
    name: "Boldare Boards",
    link: "https://boards.boldare.com/",
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy/",
  },
  {
    name: "Newsroom",
    link: "/newsroom/",
  },
];
