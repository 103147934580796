import React from "react";
import { Helmet } from "react-helmet";
import { config } from "config";

type SeoProps = {
  canonical?: string;
  cloudinary?: boolean;
  cover?: string;
  description?: string;
  metaTitle?: string;
  section?: string;
  slug?: string | null;
  title?: string;
  url?: string;
};

const SEO = ({
  canonical = "",
  cloudinary = false,
  cover = config.siteCover,
  description = config.siteDescription,
  metaTitle = config.siteTitle,
  section = "",
  slug = "",
  title = "",
  url = "",
}: SeoProps) => {
  const postURL =
    url ||
    (slug && section ? `${config.siteUrl}/${section}/${slug}` : config.siteUrl);
  const realPrefix = config.pathPrefix !== "/" ? config.pathPrefix : "";
  const image = cloudinary ? cover : `${config.siteUrl}${realPrefix}${cover}`;

  const canonicalUrl = canonical || `${postURL}/`;

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name={"description"} content={description} />
      <meta name={"image"} content={image} />
      {canonicalUrl && (
        <link rel={"alternate"} hrefLang={"en-us"} href={`${canonicalUrl}`} />
      )}
      {canonicalUrl && (
        <link rel={"alternate"} hrefLang={"en-gb"} href={`${canonicalUrl}`} />
      )}
      {canonicalUrl && <link rel={"canonical"} href={`${canonicalUrl}`} />}
      {/* Schema.org tags */}

      {/* OpenGraph tags */}
      <meta property={"og:url"} content={postURL} />
      <meta property={"og:type"} content={"article"} />
      <meta property={"og:title"} content={title} />
      <meta property={"og:description"} content={description} />
      <meta property={"og:image"} content={image} />
      <meta property={"fb:app_id"} content={config.siteFBAppID || ""} />

      {/* Twitter Card tags */}
      <meta name={"twitter:card"} content={"summary_large_image"} />
      <meta name={"twitter:creator"} content={"boldarecom"} />
      <meta name={"twitter:title"} content={title} />
      <meta name={"twitter:description"} content={description} />
      <meta name={"twitter:image"} content={image} />
    </Helmet>
  );
};

export default SEO;
