import React, { useRef, useLayoutEffect, FC } from "react";
import { SectionHeader } from "../SectionHeader";

import "./IntroductionSection.scss";
import { showText } from "helpers/showText";

export const IntroductionSection: FC<{ mainTimeline: any }> = ({
  mainTimeline,
}) => {
  const introWrapper = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!introWrapper.current) return;

    const intro = introWrapper.current;
    const label = introWrapper.current.querySelector(
      ".section-intro .section-header__headline-label"
    )!;
    const heading = introWrapper.current.querySelector(
      ".section-intro .heading"
    )!;

    showText(label, intro, { markers: false, display: "table" });
    showText(heading, intro, { markers: false, display: "inline" });
  }, []);

  return (
    <div
      className="padding__container section-box"
      id={"intro-section"}
      data-color="transparent"
      ref={introWrapper}
    >
      <section className={"section-intro"}>
        <div className={"section-intro__content"}>
          <SectionHeader
            additionalClass={"section-intro__heading"}
            background
            backgroundColor={"white"}
            color={"mine-shaft"}
            headingType={2}
            label={"Let us know"}
            labelColor={"yellow"}
            size={"big"}
            heading={
              "Have you ever wondered what value is offered by the products you work on?"
            }
          >
            <div />
          </SectionHeader>
        </div>
      </section>
    </div>
  );
};
