import React from "react";

import "./Slider.scss";

const EtudesSection = () => {
  return (
    <div className={"slider__container"}>
      <div className={"video__container"}>
        <iframe
          src="https://www.youtube-nocookie.com/embed/IDv2lvbfQT8"
          title="Let's make products you can be proud of."
          allow="fullscreen"
        ></iframe>
      </div>
      <div className={"video__container"}>
        <iframe
          src="https://www.youtube-nocookie.com/embed/qdCYK-BaeLs"
          title="Let's make products you can be proud of."
          allow="fullscreen"
        ></iframe>
      </div>
    </div>
  );
};

export default EtudesSection;
