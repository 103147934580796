import React from "react";
import { data } from "./data";
import Slider from "./subcomponents/Slider";
import "./QuotesSection.scss";

export const QuotesSection = () => {
  return (
    <section className={"section-quotes section-box"} data-color="#f1d624">
      <div className="padding__container">
        <Slider id={"section-quotes__slider"} items={data} />
      </div>
    </section>
  );
};
