import React, { useState } from "react";
import cookie from "react-cookies";

import Button from "components/Button";
import Paragraph from "components/Paragraph";

import "./CookieInfo.scss";

const CookieInfo = () => {
  const COOKIES_ACCEPTED_COOKIE = "cookies-accepted";
  const [cookiesAccepted, setCookiesAccepted] = useState(
    cookie.load(COOKIES_ACCEPTED_COOKIE) === "true"
  );

  const handleCookiesAccept = () => {
    setCookiesAccepted(true);
    cookie.save(COOKIES_ACCEPTED_COOKIE, "true", { path: "/" });
  };

  return cookiesAccepted ? null : (
    <div className={"cookie-info"}>
      <div className={"cookie-info__container"}>
        <Paragraph
          color={"white"}
          additionalClass={"cookie-info__description text--default"}
        >
          We use cookies. By continuing to browse the site you are agreeing
          to&nbsp;
          <a className={"link"} href={"/privacy-policy/"}>
            our privacy policy
          </a>
          .
        </Paragraph>
        <Button
          color={"white"}
          onClick={() => handleCookiesAccept()}
          additionalClass={"cookie-info__button text--white"}
          ariaName={"Cookie info button"}
          transparent
        >
          Accept and close
        </Button>
      </div>
    </div>
  );
};

export default CookieInfo;
