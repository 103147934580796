import gsap from "gsap";

export const mapAnimToTimeline = <X>(
  collection: Array<X>,
  animCallback: (collectionItem: X) => gsap.core.TimelineChild
): gsap.core.Timeline => {
  return collection.reduce(
    (acc, collectionItem) => acc.add(animCallback(collectionItem), "+=0"),
    gsap.timeline()
  );
};
