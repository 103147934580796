import React, { FC, RefObject } from "react";
import { SectionHeader } from "../SectionHeader";
import Button from "components/Button";
import background from "assets/videos/boldare_products.webm";
import background2 from "assets/videos/boldare_products.mp4";
import background2Min from "assets/videos/boldare_products_min.mp4";
import { AnimatedLogo } from "components/AnimatedLogo";
import { useHeroAnimations } from "./animations";
import SmoothScrollLink from "components/SmoothScrollLink";

import "./HeroSection.scss";
import { Loading } from "../Loading";

export const HeroSection: FC<{
  videoPreviewRef: RefObject<HTMLVideoElement>;
  mainTimeline: any;
}> = ({ videoPreviewRef, mainTimeline }) => {
  const { wrapperRef, videoRef, isReady } = useHeroAnimations();
  const desktopSmall = window.matchMedia("(min-width: 1024px)");
  return (
    <section
      className={"section-hero section-box"}
      ref={wrapperRef}
      data-color="transparent"
    >
      {!isReady && <Loading />}
      <div className={"section-hero__content"}>
        <AnimatedLogo additionalClass="image" style={{ opacity: 0 }} />
        <SectionHeader
          additionalClass={"section-hero__heading"}
          size={"extra-large"}
          color={"white"}
          headingType={1}
          style={{ opacity: "0" }}
        >
          Let's make{" "}
          <span className={"section-header__headline--span"}>products</span> you
          can be proud of!
        </SectionHeader>
        <div className={"section-hero__cta"}>
          <SmoothScrollLink
            to={"intro-section"}
            href={"#intro-section"}
            offset={100}
          >
            <Button>Start journey</Button>
          </SmoothScrollLink>
          <div className={"section-hero__cta-btn-group"}>
            <a
              href={"https://www.youtube.com/watch?v=IDv2lvbfQT8"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <Button order={"circle"} circleText={"Watch short stories"} />
            </a>
          </div>
        </div>
      </div>
      {desktopSmall.matches ? (
        <>
          <video
            ref={videoPreviewRef}
            controls={false}
            style={{
              width: "100%",
              height: "calc(100% - 80px)",
              top: "80px",
              right: "0",
              padding: "0 80px 80px 80px",
              opacity: "0",
            }}
            src={background2}
            className={"videoBackground hide-mobile hide-tablet-big"}
            autoPlay
            preload="auto"
            loop
            muted
            playsInline
          >
            <source src={background} type={"video/webm"} />
            <source src={background2} type={"video/mp4"} />
          </video>
          <video
            src={background2}
            className={"videoTag"}
            controls={false}
            preload="auto"
            loop
            muted
            playsInline
            ref={videoRef}
          >
            <source src={background} type={"video/webm"} />
            <source src={background2} type={"video/mp4"} />
          </video>
        </>
      ) : (
        <video
          src={background2Min}
          className={"videoTag"}
          controls={false}
          preload="auto"
          loop
          muted
          playsInline
          ref={videoRef}
        >
          <source src={background} type={"video/webm"} />
          <source src={background2} type={"video/mp4"} />
        </video>
      )}
      <p className="indicator">
        <span>↓</span>
        <span>Scroll down</span>
        <span>↓</span>
      </p>
    </section>
  );
};
