import React from "react";
import { config } from "config";

import Link from "components/Button/Link/Link";
import LogoWhite from "assets/images/brand/logo-boldare-products.svg";

import IconSVG from "components/IconSVG/IconSVG";

import { classNames } from "helpers";

import "./NavBar.scss";

type NavbarProps = {
  additionalClass?: string;
  banner?: Object;
  button: {
    link: string;
    id: string;
    label: string;
  };
  show?: boolean;
  style?: React.CSSProperties;
};

export const NavBar = ({
  additionalClass,
  button,
  show,
  style,
}: NavbarProps) => {
  return (
    <>
      <div className={classNames("navbar", additionalClass, {})} style={style}>
        <header className={"navbar__brand w-container"}>
          <div className={"navbar__logos "}>
            <Link
              to={"/"}
              additionalClass={"navbar__logo-container"}
              id={"navbar-logo"}
            >
              <img
                src={LogoWhite}
                className={"image navbar__logo--img hide-mobile hide-tablet"}
                alt={"Digital product design company Boldare"}
                loading={"lazy"}
              />
            </Link>
          </div>
          <nav
            id={"navMenu"}
            className={classNames("navbar__menu")}
            aria-label={"main navigation"}
          >
            {typeof window !== "undefined" && window.location && (
              <Link
                id={button.id}
                to={button.link}
                underline={"white"}
                additionalClass={"text--white"}
              >
                {button.label}
              </Link>
            )}
            <ul className={"navbar__menu-ul"}>
              <li className={classNames("navbar__menu-item")}>
                <Link
                  to={config.facebook}
                  id={"footer-facebook"}
                  rel={"nofollow"}
                >
                  <IconSVG name={"fb-light"} size={"medium"} color={"black"} />
                </Link>
                <Link
                  to={config.instagram}
                  id={"footer-instagram"}
                  rel={"nofollow"}
                >
                  <IconSVG name={"ig-light"} size={"medium"} color={"black"} />
                </Link>
                <Link
                  to={config.linkedin}
                  id={"footer-linkedin"}
                  rel={"nofollow"}
                >
                  <IconSVG name={"li-light"} size={"medium"} color={"black"} />
                </Link>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
};

export default NavBar;
