import React, { Fragment } from "react";
import classnames from "classnames";
import ReactHtmlParser from "react-html-parser";
import Link from "components/Button/Link/Link";
import LogoWhite from "assets/images/brand/logo-boldare-products.svg";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { wrap } from "@popmotion/popcorn";

import { SectionHeader } from "../../../SectionHeader";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import IconSVG from "components/IconSVG/IconSVG";

import "./PortfolioContent.scss";

type PortfolioContentProps = {
  id: string;
  items: {
    name: string;
    description: string;
    img: string;
    counter: string;
    caseStudy: {
      title: string;
      description: string;
      post: string;
    };
  }[];
};

const containerVariants: Variants = {
  exit: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      stiffness: 100,
    },
  },
};

const imageVariants: Variants = {
  exit: {
    scale: 1.2,
    translateX: "-45%",
    translateY: "-50%",
  },
  show: {
    scale: 1.0,
    translateX: "-50%",
    translateY: "-50%",
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  },
};

const PortfolioContent = ({ items, id }: PortfolioContentProps) => {
  const [slide, setSlide] = React.useState(0);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const slideIndex = wrap(0, items.length, slide);
  const paginate = (newDirection: number) => {
    setSlide(slide + newDirection);
  };

  const handleModal = () => {
    setModalOpen(true);
    document.body.classList.add("no-scroll");
    document.getElementsByClassName("navbar__brand")[0].classList.add("modal");
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove("no-scroll");
    document
      .getElementsByClassName("navbar__brand")[0]
      .classList.remove("modal");
  };

  return (
    <div
      className={classnames("section-portfolio__content", "glide", "slider")}
      id={id}
    >
      <AnimatePresence initial={false}>
        {items
          .filter((item, index) => index === slideIndex)
          .map((item) => (
            <Fragment key={`item-${item}`}>
              <motion.div
                initial="exit"
                animate="show"
                exit="exit"
                key={`name-${item}`}
                className="section-portfolio__content-name"
              >
                <Heading color={"white"} size={"large"}>
                  <span onClick={() => handleModal()}>{item.name}</span>
                </Heading>
                <motion.div className="slider__nav">
                  <button
                    className={
                      "glide__arrow glide__arrow--left slider-nav__arrow slider-nav__arrow--left"
                    }
                    data-glide-dir={"<"}
                    onClick={() => paginate(-1)}
                  >
                    <IconSVG
                      name={"arrow-left-light"}
                      color={"white"}
                      size={"medium"}
                    />
                  </button>
                  <button
                    className={
                      "glide__arrow glide__arrow--right slider-nav__arrow slider-nav__arrow--right"
                    }
                    data-glide-dir={">"}
                    onClick={() => paginate(1)}
                  >
                    <IconSVG
                      name={"arrow-right-light"}
                      color={"white"}
                      size={"medium"}
                    />
                  </button>
                </motion.div>
                <div
                  className={"section-portfolio__content-counter text--white"}
                >
                  <span>{item.counter}</span> / 04
                </div>
              </motion.div>
              <motion.img
                variants={imageVariants}
                initial="exit"
                animate="show"
                exit="exit"
                key={item.img}
                className="section-portfolio__content-img"
                src={item.img}
                alt={item.name}
              />
              <motion.div
                variants={containerVariants}
                initial="exit"
                animate="show"
                exit="exit"
                key={item.name}
                className="section-portfolio__content-description hide-mobile"
              >
                <Paragraph color={"white"} additionalClass={"text--light"}>
                  <b className={"text--demibold"}>Description:</b>
                  <br />
                  <br />
                  {ReactHtmlParser(item.description)}
                </Paragraph>
              </motion.div>
              {isModalOpen && (
                <motion.div key={`modal-${item}`} className={"modal"}>
                  <div className={"navbar"}>
                    <header className={"navbar__brand"}>
                      <div className={"navbar__logos"}>
                        <Link
                          to={"/"}
                          additionalClass={"navbar__logo-container"}
                          id={"navbar-logo"}
                        >
                          <img
                            src={LogoWhite}
                            className={
                              "image navbar__logo--img hide-mobile hide-tablet"
                            }
                            alt={"Digital product design company Boldare"}
                            loading={"lazy"}
                          />
                        </Link>
                      </div>
                      <nav
                        id={"navMenu"}
                        className={"navbar__menu"}
                        aria-label={"main navigation"}
                      >
                        <button
                          className={"modal__close"}
                          onClick={() => closeModal()}
                        >
                          <IconSVG
                            name={"close"}
                            color={"white"}
                            size={"medium"}
                          />
                        </button>
                      </nav>
                    </header>
                  </div>
                  <div className={"modal__wrapper"}>
                    <div className={"modal__content"}>
                      <div
                        data-title={item.name}
                        className="section-header__headline-label section-header__headline-label--cornflower"
                      >
                        {item.name}
                      </div>
                      <SectionHeader
                        additionalClass={"section-about__heading"}
                        background
                        backgroundColor={"mine-shaft"}
                        color={"white"}
                        headingType={2}
                        size={"large"}
                        heading={"We think about our work"}
                      >
                        {item.caseStudy.title}
                      </SectionHeader>
                      <Paragraph
                        color={"black"}
                        size={"medium-plus-small"}
                        additionalClass={
                          "modal__content-description text--bold"
                        }
                      >
                        {item.caseStudy.description}
                      </Paragraph>
                      <div className={"modal__body"}>
                        {ReactHtmlParser(item.caseStudy.post)}
                      </div>

                      <motion.div className={"slider__nav"}>
                        <button
                          className={
                            "glide__arrow glide__arrow--left slider-nav__arrow slider-nav__arrow--left"
                          }
                          data-glide-dir={"<"}
                          onClick={() => paginate(-1)}
                        >
                          <IconSVG
                            name={"arrow-left-light"}
                            color={"white"}
                            size={"medium"}
                          />
                        </button>
                        <button
                          className={
                            "glide__arrow glide__arrow--right slider-nav__arrow slider-nav__arrow--right"
                          }
                          data-glide-dir={">"}
                          onClick={() => paginate(1)}
                        >
                          <IconSVG
                            name={"arrow-right-light"}
                            color={"white"}
                            size={"medium"}
                          />
                        </button>
                      </motion.div>
                    </div>
                  </div>
                </motion.div>
              )}
            </Fragment>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default PortfolioContent;
