import React, { Fragment } from "react";
import classnames from "classnames";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import doubleQuotationMark from "assets/images/double-quotation-mark.svg";
import { SectionHeader } from "../../../SectionHeader";
import Paragraph from "components/Paragraph";
import IconSVG from "components/IconSVG/IconSVG";

import "./Slider.scss";

type SliderProps = {
  id: string;
  items: {
    name: string;
    role: string;
    quote: string;
    img: string;
  }[];
};

const containerVariants: Variants = {
  exit: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      stiffness: 100,
    },
  },
};

const Slider = ({ items, id }: SliderProps) => {
  const [slide, setSlide] = React.useState(0);
  const slideIndex = wrap(0, items.length, slide);
  const paginate = (newDirection: number) => {
    setSlide(slide + newDirection);
  };
  return (
    <div
      className={classnames("section-quotes__content", "glide", "slider")}
      id={id}
    >
      <AnimatePresence initial={false}>
        {items
          .filter((item, index) => index === slideIndex)
          .map((item) => (
            <Fragment key={item.img}>
              <motion.img
                initial="exit"
                animate="show"
                exit="exit"
                key={item.img}
                className="section-quotes__content-person-img"
                src={item.img}
                alt={item.name}
              />
              <motion.div
                variants={containerVariants}
                initial="exit"
                animate="show"
                exit="exit"
                key={item.name}
                className="section-quotes__content-quote"
              >
                <img
                  src={doubleQuotationMark}
                  alt={"Double quotation mark"}
                  className="section-quotes__content-background-img"
                />
                <img
                  src={doubleQuotationMark}
                  alt={"Double quotation mark"}
                  className="section-quotes__content-background-img section-quotes__content-background-img--right"
                />
                <SectionHeader
                  background
                  backgroundColor={"white"}
                  color={"mine-shaft"}
                  headingType={3}
                  size={"big"}
                >
                  {item.quote}
                </SectionHeader>
                <Paragraph
                  additionalClass={
                    "section-quotes__content-inscription text--demibold"
                  }
                >
                  {item.name},{" "}
                  <span className={"text--white"}>{item.role}</span>
                </Paragraph>
                <motion.div className="slider__nav">
                  <button
                    className={
                      "glide__arrow glide__arrow--left slider-nav__arrow slider-nav__arrow--black slider-nav__arrow--left"
                    }
                    data-glide-dir={"<"}
                    onClick={() => paginate(-1)}
                  >
                    <IconSVG name={"arrow-left-light"} color={"black"} />
                  </button>
                  <button
                    className={
                      "glide__arrow glide__arrow--right slider-nav__arrow--black slider-nav__arrow slider-nav__arrow--right"
                    }
                    data-glide-dir={">"}
                    onClick={() => paginate(1)}
                  >
                    <IconSVG name={"arrow-right-light"} color={"black"} />
                  </button>
                </motion.div>
              </motion.div>
            </Fragment>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default Slider;
