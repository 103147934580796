import React from "react";
import { pages, config } from "config";
import { useWindowWidth } from "hooks";
import SEO from "components/SEO";
import Layout from "components/Layout";
import NavBar from "components/NavBar";
import ForThePlanet from "modules/ForThePlanet";
import TagManager from "react-gtm-module";
const page = pages.default;
const canonical = `${config.siteUrl}/`;
const environment = process.env.NODE_ENV;
const isProduction = environment.includes("production");

const tagManagerArgs = {
  gtmId: "GTM-M2DJWJG",
};

const App = (data: any) => {
  isProduction && TagManager.initialize(tagManagerArgs);

  const isMobile = useWindowWidth() <= 768;
  const oldHeight = window.innerHeight;

  window.onresize = () => {
    if (isMobile && oldHeight > window.innerHeight) {
      window.location.reload();
    }
  };

  return (
    <>
      <SEO />
      <Layout page={page} canonical={canonical}>
        <NavBar
          additionalClass={"menu--remote"}
          show
          button={{
            link: "https://boldare.com/work/",
            id: "ga-bftp-see-projects",
            label: "See our projects",
          }}
          style={{ opacity: "0" }}
        />
        <ForThePlanet />
      </Layout>
    </>
  );
};

export default App;
