import React, { useRef, useLayoutEffect, FC, RefObject } from "react";
import Button from "components/Button";
import gsap from "gsap";
import Link from "components/Button/Link/Link";
import { SectionHeader } from "../SectionHeader";
import background from "assets/videos/boldare_products.webm";
import background2 from "assets/videos/boldare_products.mp4";

import "./AboutSection.scss";
import Paragraph from "components/Paragraph";
import { toggleSidebar } from "helpers/toggleSidebar";
import { animateVideoPreview } from "./animateVideoPreview";
import { enterSubsection } from "./enterSubsection";
import { isElement } from "helpers/isElement";

const subSection1Id = "people-and-planet";
const subSection2Id = "we-are-boldare";
const subSection3Id = "we-stay-bold";
export const AboutSection: FC<{
  videoPreviewRef: RefObject<HTMLVideoElement>;
  mainTimeline: any;
}> = ({ videoPreviewRef, mainTimeline }) => {
  const sectionAbout = useRef<HTMLDivElement | null>(null);
  // Content makers are hidden behind the scenes and make up for amount of scrolling
  // Could be refactored to useArrayRef, see: https://greensock.com/react-advanced/#useArrayRef
  const cm1 = useRef<HTMLDivElement | null>(null);
  const cm2 = useRef<HTMLDivElement | null>(null);
  const cm3 = useRef<HTMLDivElement | null>(null);
  const desktopSmall = window.matchMedia("(min-width: 1024px)");

  useLayoutEffect(
    () => {
      if (
        !desktopSmall.matches ||
        !cm1.current ||
        !cm2.current ||
        !cm3.current ||
        !sectionAbout.current
      )
        return;
      const sidebarElement = sectionAbout.current as HTMLDivElement;

      [cm1.current, cm2.current, cm3.current].forEach(
        (subsecScrollContainer) => {
          const subsection = document.querySelector(
            `#${subsecScrollContainer.dataset.markerContent}`
          );

          if (
            subsection &&
            isElement(subsection) &&
            subsection.tagName === "DIV"
          ) {
            mainTimeline.add(
              gsap
                .timeline({
                  scrollTrigger: {
                    trigger: subsecScrollContainer,
                    scrub: 0.5,
                    start: "top top",
                    end: "bottom bottom",
                  },
                })
                .add(
                  enterSubsection(
                    subsection as unknown as HTMLDivElement,
                    subsecScrollContainer
                  )
                )
            );
          }
        }
      );

      mainTimeline
        .add(toggleSidebar(sidebarElement, ".about-wrapper"))
        .add(animateVideoPreview(videoPreviewRef, ".about-wrapper"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={"about-wrapper section-box"} data-color="white">
      <section
        ref={sectionAbout}
        className={"section-about"}
        id={"section-about"}
      >
        <div className={"section-about__content"}>
          {/* FIRST SECTION */}
          <div id={subSection1Id} className={"section-about__description"}>
            {!desktopSmall.matches && (
              <video
                src={background2}
                className={"videoBackground hide-desktop"}
                autoPlay
                preload={"yes"}
                loop
                muted
                playsInline
              >
                <source src={background} type={"video/webm"} />
                <source src={background2} type={"video/mp4"} />
              </video>
            )}
            <div className={"section-about__description-content"}>
              <SectionHeader
                additionalClass={"section-about__heading"}
                background
                backgroundColor={"mine-shaft"}
                color={"white"}
                headingType={2}
                label={"At Boldare"}
                labelColor={"cornflower"}
                size={"large"}
                heading={"We think about people and the value they get from our products"}
              >
                We think about people and the value they get from our products
              </SectionHeader>
              <Paragraph
                fadedIn
                size={"medium"}
                additionalClass={"section-about__paragraph"}
                text="When it comes to cooperating with clients, picking out the best solutions and delivering effective digital products, we always keep that value in mind. Welcome to <i>Boldare Products</i> - here we’re going to explain our approach to society and the world."
              ></Paragraph>
              <br />
              <br />
              <Paragraph
                fadedIn
                size={"medium"}
                additionalClass={"section-about__paragraph"}
                text="Let's make products you can be proud of!"
              ></Paragraph>
            </div>
          </div>
          {/* SECOND SECTION */}
          <div id={subSection2Id} className={"section-about__description"}>
            {!desktopSmall.matches && (
              <video
                src={background2}
                className={"videoBackground hide-desktop"}
                autoPlay
                preload={"yes"}
                loop
                muted
                playsInline
              >
                <source src={background} type={"video/webm"} />
                <source src={background2} type={"video/mp4"} />
              </video>
            )}
            <div className={"section-about__description-content"}>
              <SectionHeader
                additionalClass={"section-about__heading"}
                background
                backgroundColor={"mine-shaft"}
                color={"white"}
                headingType={2}
                label={"About Us"}
                labelColor={"yellow"}
                size={"large"}
                heading={"We are BOLDARE"}
              >
                We are BOLDARE
              </SectionHeader>
              <Paragraph
                fadedIn
                size={"medium"}
                additionalClass={"section-about__paragraph"}
                text="We work on a wide range of different products and some of them have a direct impact on society or the environment. Aside from this, every Boldare product offers real value to the user.  We also feel proud of our work because of other aspects like intriguing technical solutions, maverick design or our well-coordinated teams. Being involved in products that are close to our hearts make us happy."
              ></Paragraph>
            </div>
          </div>
          {/* THIRD SECTION */}
          <div id={subSection3Id} className={"section-about__description"}>
            {!desktopSmall.matches && (
              <video
                src={background2}
                className={"videoBackground hide-desktop"}
                autoPlay
                preload={"yes"}
                loop
                muted
                playsInline
              >
                <source src={background} type={"video/webm"} />
                <source src={background2} type={"video/mp4"} />
              </video>
            )}
            <div className={"section-about__description-content"}>
              <SectionHeader
                additionalClass={"section-about__heading"}
                background
                backgroundColor={"mine-shaft"}
                color={"white"}
                headingType={2}
                label={"About Us"}
                labelColor={"yellow"}
                size={"large"}
                heading={"We stay BOLD"}
              >
                We stay BOLD
              </SectionHeader>
              <Paragraph
                fadedIn
                size={"medium"}
                additionalClass={"section-about__paragraph"}
                text="What does it mean? We speak out loud what we think and share our opinions bravely with the world. Bold(are) people are helpful and engaged when important discussions are born. Sometimes, we take bold actions. Always, we bear in mind that improving habitat is limitless."
              ></Paragraph>
              <Link additionalClass={'career-link'} to={"https://boldare.com/career/"}>
                <Button transparent>Join us!</Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className={"section-about__virtual-content"}>
        <div
          ref={cm1}
          className="contentMarker"
          data-marker-content={subSection1Id}
          style={{ height: "100vh" }}
        ></div>
        <div
          ref={cm2}
          className="contentMarker"
          data-marker-content={subSection2Id}
          style={{ height: "100vh" }}
        ></div>
        <div
          ref={cm3}
          className="contentMarker"
          data-marker-content={subSection3Id}
          style={{ height: "150vh" }}
        ></div>
      </div>
    </div>
  );
};
