import React, { RefObject } from "react";
import { classNames } from "helpers";
import "./SectionHeader.scss";
import Heading from "components/Heading";

type SectionHeaderProps = {
  additionalClass?: string;
  background?: boolean;
  backgroundColor?: string;
  centered?: boolean;
  children?: React.ReactNode;
  color?: string;
  fixed?: boolean;
  headingType?: null | 1 | 2 | 3 | 4 | 5 | 6;
  label?: string;
  labelColor?: string;
  labelRef?: RefObject<HTMLDivElement>;
  headingRef?: RefObject<HTMLHeadingElement>;
  noBottomMargin?: boolean;
  rtl?: boolean;
  size?: SizeType;
  heading?: string;
  style?: React.CSSProperties;
};

type SizeType =
  | "extra-large"
  | "large"
  | "big"
  | "medium"
  | "small"
  | "tiny-plus"
  | "tiny";

export const SectionHeader = ({
  additionalClass = "",
  background = false,
  backgroundColor = "mine-shaft",
  centered = false,
  children,
  color = "",
  headingType = null,
  label = "",
  labelRef = undefined,
  headingRef = undefined,
  labelColor = "yellow",
  rtl = false,
  size = "big",
  heading = "",
  style = {},
}: SectionHeaderProps) => {
  const setSizeClass = (type: SizeType) => {
    const sizes = {
      "extra-large": ["extra-large", 1, "medium"],
      large: ["large", 1, "medium"],
      big: ["big", 2, "medium"],
      medium: ["big", 3, "medium"],
      small: ["medium-plus", 3, "regular"],
      "tiny-plus": ["medium", 3, "regular"],
      tiny: ["small", 4, "small"],
    };
    return sizes[type];
  };

  const headingSize = setSizeClass(size);

  return (
    <div
      className={classNames(
        "section-header",
        {
          [`section-header--${size}`]: size,
          "section-header--centered": centered,
        },
        additionalClass
      )}
      style={style}
    >
      {children && (
        <div
          className={classNames("section-header__headline", {
            "section-header__headline--right": rtl,
          })}
        >
          {label && (
            <div
              ref={labelRef}
              data-title={label}
              className={classNames("section-header__headline-label", {
                [`section-header__headline-label--${labelColor}`]: labelColor,
                "section-header__headline-label--right": rtl,
              })}
            >
              {label}
            </div>
          )}
          <Heading
            ref={headingRef}
            color={color}
            size={headingSize[0]}
            type={headingType}
            title={heading}
            additionalClass={classNames({
              [`section-header__headline--${backgroundColor}`]: background,
            })}
          >
            {children}
          </Heading>
        </div>
      )}
    </div>
  );
};
