import gsap from "gsap";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useVideoLoader } from "hooks/useVideoLoader";

export const useHeroAnimations = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isReady, setIsReady] = useState(false);
  const [started, setStarted] = useState(false);

  useLayoutEffect(() => {
    if (isReady && wrapperRef.current && !started) {
      setStarted(true);
      heroAnimations(wrapperRef.current);
    }
  }, [isReady, wrapperRef, started]);

  useVideoLoader(videoRef, () => setIsReady(true));

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoRef]);

  return {
    wrapperRef,
    videoRef,
    isReady,
    onCanPlayThrough: () => setIsReady(true),
  };
};

export const heroAnimations = async (wrapper: HTMLDivElement) => {
  const navbar = document.querySelector(".navbar.menu--remote");
  const mainVideo = wrapper.querySelector<HTMLVideoElement>(
    "video.videoBackground"
  )!;
  const backVideo = wrapper.querySelector<HTMLVideoElement>("video.videoTag")!;
  const heading = wrapper.querySelector(".section-hero__heading");
  const buttons = wrapper.querySelector(".section-hero__cta");
  const logo = wrapper.querySelector(".animated-logo");
  const boldareImg = wrapper.querySelector(".animated-logo__boldare > img");
  const productsImg = wrapper.querySelector(".animated-logo__products > img");
  const desktopSmall = window.matchMedia("(min-width: 1024px)");

  if (desktopSmall.matches) {
    mainVideo.play();
  }

  backVideo.play();

  const timeline = gsap.timeline();

  if (desktopSmall.matches) {
    // videos opacity
    timeline.fromTo(
      [mainVideo, backVideo],
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
      }
    );
  }

  // logo separator opacity
  timeline.fromTo(
    logo,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
    },
    `>-0.5`
  );

  // "boldare" and "products" move in
  const LOGO_OPENING_TIME = 1.1;
  timeline.add([
    timeline.fromTo(
      boldareImg,
      {
        transform: "translateX(120%)",
      },
      {
        transform: "translateX(0%)",
        ease: "power2.inOut",
        duration: LOGO_OPENING_TIME,
      },
      `<`
    ),
    timeline.fromTo(
      productsImg,
      {
        transform: "translateX(-120%)",
      },
      {
        transform: "translateX(0%)",
        ease: "power2.inOut",
        duration: LOGO_OPENING_TIME,
      },
      "<"
    ),
  ]);

  // rest of the elements
  const ANIM_TIME = 0.8;
  timeline.fromTo(
    heading,
    {
      opacity: 0,
      transform: "translateY(20px)",
    },
    {
      opacity: 1,
      transform: "translateY(0px)",
      duration: ANIM_TIME,
    },
    "<"
  );
  timeline.fromTo(
    buttons,
    {
      opacity: 0,
      transform: "translateY(20px)",
    },
    {
      opacity: 1,
      transform: "translateY(0px)",
      duration: ANIM_TIME,
    },
    "<"
  );
  timeline.fromTo(
    navbar,
    {
      opacity: 0,
      transform: "translateY(-20px)",
    },
    {
      opacity: 1,
      transform: "translateY(0px)",
      duration: ANIM_TIME,
      scrollTrigger: {
        trigger: ".section-hero",
        scrub: 1.5,
        start: "center 30%",
        end: "+=30%",
      },
    }
  );
};
