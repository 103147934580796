import React from "react";
import { SectionHeader } from "../SectionHeader";
import { data } from "./data";
import PortfolioContent from "./subcomponents/PortfolioContent";
import "./PortfolioSection.scss";
import Paragraph from "components/Paragraph";

export const PortfolioSection = () => {
  return (
    <section className={"section-portfolio section-box"} id={'portfolio-section'} data-color="#242424">
      <div className="padding__container">
        <SectionHeader
          additionalClass={"section-portfolio__heading"}
          background
          backgroundColor={"white"}
          color={"mine-shaft"}
          headingType={2}
          size={"big"}
          centered
        >
          We create technology that improves lives
        </SectionHeader>
        <Paragraph
          color={"white"}
          size={"medium"}
          additionalClass={"section-portfolio__description"}
        >
          We make products we are proud of, and we share them with our families, friends and those closest to us. Are you curious about what we have already built?
        </Paragraph>
        <PortfolioContent id={"section-portfolio__slider"} items={data} />
      </div>
    </section>
  );
};
