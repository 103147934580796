import { useState, useCallback, useEffect, useRef } from "react";

export const useVideoLoader = (
  videoRef: React.RefObject<HTMLVideoElement>,
  onVideoLoaded?: (video: HTMLVideoElement) => any
): number => {
  const isLoaded = useRef(false);
  const [progress, setProgress] = useState(0);

  const checkStatus = useCallback(() => {
    if (!videoRef.current || isLoaded.current) return;
    if (videoRef.current.readyState === 4 && !isLoaded.current) {
      isLoaded.current = true;
      setProgress(1);
      onVideoLoaded?.(videoRef.current);
    }
  }, [videoRef, onVideoLoaded, isLoaded]);

  useEffect(() => {
    checkStatus();
    const video = videoRef.current;
    const progressListener = function (this: HTMLVideoElement) {
      checkStatus();
      if (this.buffered.length === 0) return;
      setProgress(this.buffered.end(0) / this.duration);
    };
    video?.addEventListener("progress", progressListener);
    video?.addEventListener("loadeddata", checkStatus, { once: true });
    return () => {
      video?.removeEventListener("progress", progressListener);
    };
  }, [videoRef, checkStatus, isLoaded]);
  return progress;
};
