import React from "react";
import { classNames } from "helpers";
import "./Link.scss";

type LinkProps = {
  children: React.ReactNode;
  to: string;
  additionalClass?: string;
  underline?: string;
  arrow?: boolean;
  blank?: boolean;
  id?: string;
  rel?: string;
};

const Link = ({
  blank = false,
  children,
  to,
  additionalClass = "",
  underline = "",
  arrow = false,
  ...other
}: LinkProps) => {
  let isExternal = true;

  if (typeof window !== "undefined" && window.location) {
    isExternal = new RegExp(
      `^((f|ht)tps?:)?//(?!${window.location.host})`
    ).test(to);
  }

  return (
    <a
      className={classNames(
        "link",
        {
          "link--anim-arrow": arrow,
          link__underline: underline,
          [`link__underline--${underline}`]: underline,
        },
        additionalClass
      )}
      href={to}
      rel="noopener noreferrer"
      target={isExternal || blank ? "_blank" : "_self"}
      {...other}
    >
      {children}
    </a>
  );
};

export default Link;
