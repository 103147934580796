import gsap from "gsap";

export const textDatasetSelector = (el: Element) => (el as any).dataset.title;

export const showText = (
  textEl: Element | HTMLHeadingElement,
  containerEl: HTMLElement | HTMLDivElement,
  options: {
    textSelector?: (x: Element) => string;
    display?: string;
    markers?: boolean;
    typeStart?: string;
    typeEnd?: string;
    removeStart?: string;
    removeEnd?: string;
  } = {
    display: "inline",
    markers: false,
  }
) => {
  return gsap
    .timeline()
    .to(
      textEl, // element hide
      {
        ...(options.display ? { display: "none" } : {}),
        scrollTrigger: {
          trigger: containerEl,
          toggleActions: "play none none reset",
        },
      }
    )
    .from(
      textEl, // text remove
      {
        text: {
          value: "",
        },
        scrollTrigger: {
          trigger: containerEl,
          toggleActions: "play none none reset",
        },
      }
    )
    .fromTo(
      // type back (remove) text
      textEl,
      {
        autoAlpha: 1,
        immediateRender: false,
        text: options.textSelector
          ? options.textSelector(textEl)
          : (textEl as any).title || (textEl as any).dataset.title,
      },
      {
        text: "",
        immediateRender: false,
        autoAlpha: 1,
        scrollTrigger: {
          trigger: containerEl,
          scrub: 0.5,
          markers: options?.markers || false,
          start: options?.removeStart || "center 0%",
          end: options?.removeEnd || "+=45%",
        },
      }
    )
    .fromTo(
      // type text
      textEl,
      { autoAlpha: 1, text: "", immediateRender: false },
      {
        immediateRender: false,
        text: options.textSelector
          ? options.textSelector(textEl)
          : (textEl as any).title || (textEl as any).dataset.title,
        autoAlpha: 1,
        ...(options.display ? { display: options.display } : {}),
        scrollTrigger: {
          trigger: containerEl,
          scrub: 0.5,
          markers: options?.markers || false,
          start: options?.typeStart || "center 55%",
          end: options?.typeEnd || "+=10%",
        },
      }
    );
};
