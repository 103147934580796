export const classNames = (...args: any[]) => {
  let classes: any[] = [];

  args
    .filter(Boolean) // filter out falsy values
    .forEach((arg) => {
      const argType = typeof arg;

      if (Array.isArray(arg) && arg.length) {
        classes = [...classes, ...classNames(...arg)];
        return;
      }

      if (argType === "object") {
        // iterate over received object and check if class name conditionals are truthy
        Object.entries(arg).forEach((item) => {
          const [key, value] = item;

          if (Object.hasOwnProperty.call(arg, key) && value) {
            classes.push(key);
          }
        });
        return;
      }

      if (argType === "string" || argType === "number") {
        classes.push(arg);
      }
    });

  // remove duplicates and join all classes into a single string
  return Array.from(new Set(classes)).join(" ");
};
