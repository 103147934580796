import gsap from "gsap";
import { RefObject } from "react";
export const animateVideoPreview = (
  videoPreviewRef: RefObject<HTMLVideoElement>,
  containerEl: HTMLElement | string,
  options: { markers?: boolean } = { markers: false }
) => {
  const desktopSmall = window.matchMedia("(min-width: 1024px)");
  if (!desktopSmall) return;
  const videoEl = videoPreviewRef.current;
  return gsap
    .timeline()
    .fromTo(
      // shrink / expand based on section
      videoEl,
      {
        width: "100%",
        height: "calc(100% - 80px)",
        top: "80px",
        right: "0",
        padding: "0 80px 80px 80px",
      },
      {
        width: "45%",
        height: "calc(100vh - 20vw)",
        right: "10vw",
        top: "10vw",
        padding: "0",
        scrollTrigger: {
          trigger: containerEl,
          start: "top top",
          end: "+=5%",
          scrub: 1.5,
          toggleActions: "play none none reverse",
        },
      }
    )
    .fromTo(
      // remove video when scrolled past container
      videoEl,
      {
        display: "block",
      },
      {
        display: "none",
        scrollTrigger: {
          trigger: containerEl,
          start: "top top",
          end: "bottom bottom",
          scrub: 0.5,
          toggleActions: "none play reverse none",
        },
      }
    );
};
