import React from "react";
import { classNames } from "helpers";
import "./Paragraph.scss";

type ParagraphProps = {
  additionalClass?: string;
  size?: string;
  color?: string;
  spaced?: boolean;
  text?: string | null;
  children?: React.ReactNode;
  nested?: boolean;
  fadedIn?: boolean;
};

const Paragraph = React.forwardRef<HTMLDivElement, ParagraphProps>(
  (
    {
      additionalClass = "",
      color = "default",
      children,
      spaced = false,
      size = "regular",
      text = null,
      nested = false,
      fadedIn = false,
    }: ParagraphProps,
    ref
  ) => {
    const desktopSmall = window.matchMedia("(min-width: 1024px)");
    const classes = classNames(
      "paragraph",
      {
        "paragraph--spaced": spaced,
        [`text--${color}`]: color,
        [`paragraph--${size}`]: size,
      },
      additionalClass
    );

    return fadedIn ? (
      nested ? (
        <div ref={ref} className={classes} data-title={text}>
          {desktopSmall.matches ? "" : text}
        </div>
      ) : (
        <p ref={ref} className={classes} data-title={text}>
          {desktopSmall.matches ? "" : text}
        </p>
      )
    ) : nested ? (
      <div ref={ref} className={classes} data-title={text}>
        {children}
      </div>
    ) : (
      <p ref={ref} className={classes} data-title={text}>
        {children}
      </p>
    );
  }
);

export default Paragraph;
