import React from "react";
import { pages } from "config/pages";
import { getCloudinaryUrl } from "helpers";
import CookieInfo from "components/CookieInfo";

import "styles/main.scss";
import SEO from "components/SEO";

type LayoutProps = {
  children?: React.ReactNode;
  page?: any;
  canonical?: string;
  cloudinary?: boolean;
  url?: string;
};

const Layout = ({ children, page, canonical = "", url }: LayoutProps) => {
  let pathBase = null;

  if (typeof window !== "undefined" && window.location) {
    const { pathname } = window.location;
    pathBase = pathname.split("/")[1];
  }

  const seoCanonical = canonical || page || pages.default;
  const { metaTitle, siteContent, siteCover, title } = page || pages.default;

  return (
    <div>
      <SEO
        title={title}
        metaTitle={metaTitle}
        description={siteContent}
        cover={getCloudinaryUrl(siteCover, "f_auto,q_auto")}
        slug={pathBase}
        canonical={seoCanonical}
        url={url}
        cloudinary
      />
      <div>{children}</div>
      <CookieInfo />
    </div>
  );
};

export default Layout;
