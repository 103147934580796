import "./AnimatedLogo.scss";
import boldareImg from "assets/images/brand/animated_logo/boldare.svg";
import productsImg from "assets/images/brand/animated_logo/products.svg";
import separatorImg from "assets/images/brand/animated_logo/separator.svg";
import React from "react";
import { classNames } from "helpers";

export type AnimatedLogoProps = {
  additionalClass?: string;
  style?: React.CSSProperties;
};

export const AnimatedLogo = ({ style, additionalClass }: AnimatedLogoProps) => {
  return (
    <div
      className={classNames(
        "animated-logo animated-logo__container",
        additionalClass
      )}
      style={style}
    >
      <div className="animated-logo__frame animated-logo__boldare">
        <img src={boldareImg} alt="" />
      </div>
      <div className="animated-logo__frame">
        <img src={separatorImg} alt="" />
      </div>
      <div className="animated-logo__frame animated-logo__products">
        <img src={productsImg} alt="" />
      </div>
    </div>
  );
};
