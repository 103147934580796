import gsap from "gsap";
export const toggleSidebar = (
  sidebarEl: Element,
  containerEl: HTMLElement | string,
  options: { markers?: boolean } = { markers: false }
) => {
  const desktopSmall = window.matchMedia("(min-width: 1024px)");
  if (!desktopSmall) return;
  return gsap
    .timeline()
    .fromTo(
      // shrink / expand based on section
      sidebarEl,
      {
        xPercent: -100,
      },
      {
        xPercent: 0,
        scrollTrigger: {
          trigger: containerEl,
          start: "top top",
          end: "+=5%",
          scrub: 1.5,
          toggleActions: "play none none reverse",
        },
      }
    )
    .fromTo(
      // shrink / expand based on section
      sidebarEl,
      {
        width: "35vw",
        maxWidth: "35vw",
      },
      {
        width: "100%",
        maxWidth: "unset",
        xPercent: 0,
        scrollTrigger: {
          trigger: containerEl,
          start: "bottom 90%",
          end: "+=5%",
          scrub: 1.5,
          toggleActions: "play none none reverse",
        },
      }
    );
};
