export const jobs = [
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/product-designer/",
      },
      frontmatter: {
        title: "Product Designer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 19, 2018",
          slug: "product-designer",
          type: "career",
          slugType: null,
          category: "design",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Product Designer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/2E800DD80D/apply/",
          banner: "/img/Boldare-office-stage-Pawel.jpg",
          technical: [
            {
              skill: "Experience to build products based on business goals ",
            },
            {
              skill: "Experience to validate solutions ",
            },
            {
              skill: "Practice to solve user problems",
            },
            {
              skill: "knowledge of current design trends",
            },
            {
              skill:
                "significant knowledge of software, good practices, and patterns in graphic design",
            },
            {
              skill:
                "knack for aesthetics and precision, as well as attention to detail and pixel-perfect approach",
            },
            {
              skill:
                "knowledge of UX guidelines, ability to design mockups and build prototypes",
            },
            {
              skill: "ability to make quick and firm decisions",
            },
            {
              skill: "good communication and organizational skills",
            },
            {
              skill:
                "fluency in English and Polish (B2+ level) to easily communicate with clients",
            },
          ],
          additional: [
            {
              skill: "experience in conducting workshops for clients",
            },
            {
              skill:
                "passion for highly polished designs with flawless interactions",
            },
            {
              skill:
                "experience with working in interdisciplinary teams in a startup-like atmosphere",
            },
          ],
          specifics: [
            {
              specific:
                "You'll be **designing for real** - no fuss, logical process and only foreign clients.",
            },
            {
              specific:
                "**Collaborating with various internal development teams** to satisfy product business strategies and end-users' needs.",
            },
            {
              specific:
                "You desire to **lead up workshops with our clients** to figure out and fully understand product goals.",
            },
            {
              specific:
                "**Logic information architectures, compelling user flows, low and hi-fidelity wireframes and prototypes** - these are the basic terms in your dictionary.",
            },
            {
              specific:
                "You'll deliver highly functional, user-centered and beautiful, interactive designs.",
            },
            {
              specific:
                "**True Scrum and Agile methodologies** is what you embrace on a daily basis.",
            },
            {
              specific:
                "**Testing the effects of your team's work** during manual acceptance tests is like a cherry on top for you while releasing a world-class design.",
            },
            {
              specific:
                "It's time to become a part of our **community-focused initiatives** i.e. Designers Coffee!",
            },
          ],
          photo: "/img/Boldare-office-conference-room.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume and portfolio",
              details:
                "It’s going to be verified by our designers. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "03",
              step: "Trial day",
              details:
                "Works both ways. You can take a closer look on the way your team works real-time. We get to know you better :)",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "dea7f9ff-f044-587f-8e01-6c9b4c90b8ea",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/java-developer/",
      },
      frontmatter: {
        title: "Java Developer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 18, 2018",
          slug: "java-developer",
          type: "career",
          slugType: null,
          category: "development",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Java Developer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/261632A977/apply/",
          banner: "/img/25094713777_cae2db29e7_k-1-.jpg",
          technical: [
            {
              skill: "3+ years of experience in Java programming (Java 11+)",
            },
            {
              skill: "strong knowledge of Spring Boot framework",
            },
            {
              skill:
                "Experience with Hibernate/JPA, SQL (Postgres preferred) and no-sql database",
            },
            {
              skill: "Strong knowledge about REST API",
            },
            {
              skill: "Understanding and ability to use of design patterns",
            },
            {
              skill: "Knowledge of Docker/Kubernetes",
            },
            {
              skill: "Knowledge of maven or gradle",
            },
            {
              skill: "Knowledge of JUnit and Mockito",
            },
            {
              skill:
                "Proficient understanding of code versioning tools (Git preferably)",
            },
            {
              skill: "Command of English and Polish on B2+ level",
            },
          ],
          additional: [
            {
              skill: "Experience with DDD ",
            },
            {
              skill: "Experience with elasticsearch and mongodb",
            },
            {
              skill: "Experience with event sourcing",
            },
            {
              skill: "Knowledge IaC",
            },
            {
              skill: "SOAP experience",
            },
            {
              skill: "Experience with message brokers (preferably Kafka)",
            },
            {
              skill:
                "Knowledge of AWS (in particular SQS, SNS, S3, EC2, RDS, CloudFormation)",
            },
          ],
          specifics: [
            {
              specific:
                "You are about to join **group talented and savvy JAVA Developers** to build well-designed, user-centered apps for our foreign clients.",
            },
            {
              specific:
                "You'll be working **hand in hand with the client’s team** without unnecessary proxies.",
            },
            {
              specific:
                "Developing complex products in an **“A-Z manner”** is what you desire (we’re not code-monkeys!). You are **willing to propose outcomes** apart from just implementing them.",
            },
            {
              specific:
                "**True Scrum and Agile methodologies** is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re the **planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "**Code review and using best practices (TDD, BDD etc.)** are the habits you cannot live without.",
            },
            {
              specific:
                "You’ll have the chance to **create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "/img/37048004146_cf794c0736_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 24h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call ",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps.",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "24e46a6b-867b-50a7-be58-4266bfc9b01f",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/php-developer/",
      },
      frontmatter: {
        title: "PHP Developer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 19, 2018",
          slug: "php-developer",
          type: "career",
          slugType: null,
          category: "development",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "PHP Developer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/063D715D69/apply/",
          banner: "/img/36401002694_aa9bcf725b_k-1-.jpg",
          technical: [
            {
              skill:
                "1+ years ( 3-4+ years for a mid/senior position) of experience in object-oriented PHP programming",
            },
            {
              skill:
                "Knowledge of PHP web frameworks (Symfony 4 or higher preferably)",
            },
            {
              skill: "comprehensive understanding of database systems",
            },
            {
              skill: "Understanding and ability to use of design patterns",
            },
            {
              skill:
                "Proficient understanding of code versioning tools (Git preferably)",
            },
            {
              skill: "Strong knowledge about REST API",
            },
            {
              skill: "command of English and Polish on B2+ level",
            },
            {
              skill:
                "Ability to write unit/functional tests and perform code reviews",
            },
          ],
          additional: [
            {
              skill: "knowledge of JavaScript frameworks",
            },
            {
              skill:
                "knowledge of Agile development methodologies (Scrum preferably)",
            },
            {
              skill: "Knowledge of queue concept using i.e. RabbitMQ, SQS",
            },
            {
              skill: "Knowledge of AWS/Docker/Kubernetes",
            },
          ],
          specifics: [
            {
              specific:
                "You are about to join **15 talented and savvy PHP Developers** to build well-designed, user-centered apps for our foreign clients.",
            },
            {
              specific:
                "\n\nYou’ll use proven technologies that are up-to-date - PHP 7 or higher",
            },
            {
              specific:
                "\n\nYou ‘ll have the opportunity to create products where the **DDD approach is familiar**",
            },
            {
              specific:
                "You'll be working **hand in hand with the client’s team** without unnecessary proxies.",
            },
            {
              specific:
                "Developing complex products in an **“A-Z manner”** is what you desire (we’re not code-monkeys!). You are **willing to propose outcomes** apart from just implementing.",
            },
            {
              specific:
                "You’re **excited about working closely with **Product Designers, Frontend Developers, Scrum Masters and QAs (they are your new best friends now!).",
            },
            {
              specific:
                "**True Scrum and Agile methodologies **is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re **the planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "\n\nYou’ll have the **opportunity to implement an API** that communicates with the SPA",
            },
            {
              specific:
                "**Code review and using best practices (TDD, BDD etc.)** are habits you cannot live without.",
            },
            {
              specific:
                "You’ll have the chance to **create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "/img/36423959063_f34ce8d3f3_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 72h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "49271efb-4e23-5d4f-930b-a1da5812f8a5",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/devops-engineer/",
      },
      frontmatter: {
        title: "DevOps Engineer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 18, 2018",
          slug: "devops-engineer",
          type: "career",
          slugType: null,
          category: "development",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "DevOps Engineer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "junior",
                  min: 4500,
                  max: 9000,
                },
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/08EAC40C1C/apply/",
          banner: "/img/02471-_tst1499.jpg",
          technical: [
            {
              skill:
                "ability to research and test various tools/platforms to find the most suitable one",
            },
            {
              skill:
                "feeling comportable with programming in the field (Python, Bash, etc.)",
            },
            {
              skill:
                "extensive knowledge and practice in Linux (all our stack is Linux based)",
            },
            {
              skill:
                "using multiple Amazon AWS services, choosing and configuring the suitable ones (EC2, RDS, SES, S3, ECS)",
            },
            {
              skill:
                "knowledge of modern container-based management tools (i.e. Kubernetes, Rancher, Docker Swarm)",
            },
            {
              skill: "feeling comfortable with using Terraform",
            },
            {
              skill: "familiarity with Docker (we use it in 80% of projects)",
            },
            {
              skill: "flexibility and positive attitude toward team working",
            },
          ],
          additional: [
            {
              skill:
                "knowledge of PHP/Java/JavaScript so that you can support development teams as well as prepare DevOps innovations",
            },
            {
              skill:
                "practice with infrastructure management tools such as Ansible, Chef or Puppet.",
            },
            {
              skill: "knowledge of networking domain basics",
            },
          ],
          specifics: [
            {
              specific:
                "You will be responsible for **researching and testing new software.**",
            },
            {
              specific:
                "**Continuous Integration, Continuous Development, Continuous Testing, Continuous Deployment and Continuous Monitoring** are the phrases you believe will rescue the world!",
            },
            {
              specific:
                "**Configuring new infrastructures** based on specifics and supporting our teams during the development process to ensure that solutions are designed and developed with relevant scale and performance.",
            },
            {
              specific:
                "You'll be working with the **best DevOps tools (of your choice)** to ensure a proficient level of security and flawless software operations.",
            },
            {
              specific:
                "**Supporting our customers** and providing maintenance for the infrastructure of their products.",
            },
          ],
          photo: "/img/36423959063_f34ce8d3f3_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 24h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                " We just wanna catch you for 15 minutes to ask basic questions and set the next steps.",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "e32f532a-e08b-5df6-a082-2aa097beada1",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/qa-engineer/",
      },
      frontmatter: {
        title: "QA Engineer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 19, 2018",
          slug: "qa-engineer",
          type: "career",
          slugType: null,
          category: "qualityAssurance",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "QA Engineer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/C04D4AAC4E/apply/",
          banner: "/img/05053-_tst4081-1-.jpg",
          technical: [
            {
              skill:
                "Experience in automating tests in at least two technologies",
            },
            {
              skill:
                "Experience with Selenium WebDriver and Page Object Pattern",
            },
            {
              skill: "Experience with Git",
            },
            {
              skill:
                "Knowledge of at least one of the following techniques: BDD, TDD, ATDD",
            },
            {
              skill:
                "Pracitice in using BDD frameworks (i.e. Cucumber, Behat, JBehave)",
            },
            {
              skill:
                "Good understanding of penetration testing and performance testing",
            },
            {
              skill: "Practice with Gherkin",
            },
            {
              skill:
                "Good command of Polish and English (B2+ level) to easily communicate with clients",
            },
            {
              skill:
                "Know-how to write clean bug reports and how to write test plans",
            },
            {
              skill: "experience in REST API ",
            },
          ],
          additional: [
            {
              skill: "Knowledge of Appium",
            },
            {
              skill:
                "Familiarity with Continuous Integration tools (i.e. Jenkins, TravisCI, GitLab CI)",
            },
            {
              skill:
                "Experience with distribution of automating tests and cross-platform testing",
            },
          ],
          specifics: [
            {
              specific:
                "You'll be responsible for **creating detailed, comprehensive and well-structured test plans** and test cases.",
            },
            {
              specific:
                "Developing and **executing exploratory and automated tests to ensure product** runs smoothly and meets client's needs.",
            },
            {
              specific:
                "You'll be **providing end-to-end solutions** to software quality problems.",
            },
            {
              specific:
                "Monitoring of all stages of software development to **identify and resolve system malfunctions** (including debugging process results).",
            },
            {
              specific:
                "You're the one to **track quality assurance metrics**, like defect densities and open defect counts.",
            },
            {
              specific:
                "You **stay up-to-date with new testing tools and strategies**.",
            },
            {
              specific:
                "**True Scrum and Agile methodologies** is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re the **planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "You’ll have the chance to **create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "/img/38884953394_076bd94d71_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 72h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phoe call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "c3ed9bed-0e99-5e02-82ea-23e45bcfe006",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/prototype-mvp-process-guide/",
      },
      frontmatter: {
        title: " Prototype/ MVP Process Guide ",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "June 21, 2021",
          slug: " prototype/MVP-process-guide ",
          type: "career",
          slugType: null,
          category: "product",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: " Prototype/ MVP Process Guide ",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 8000,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 11000,
                  max: 17000,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 8000,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 11000,
                  max: 17000,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 8000,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 11000,
                  max: 17000,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 8000,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 11000,
                  max: 17000,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 8000,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 11000,
                  max: 17000,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/D7B2D3D78A/apply/",
          banner: "How_much_does_it_cost_to_outsource_software_development.jpg",
          technical: [
            {
              skill:
                "2+ years of experience in roles related to Digital Product Discovery and Development (ie. Scrum Master, Product Owner, Business Analyst, etc.)",
            },
            {
              skill:
                "Practical knowledge of product discovery and development methods and techniques (i.e. Lean Startup, Design Thinking, business model creation and validation)",
            },
            {
              skill:
                "Experience in designing and executing workshops for clients",
            },
            {
              skill: "Deep understanding and practice of Lean Startup approach",
            },
            {
              skill: "Exceptional facilitation and communication skills",
            },
            {
              skill: "Client facing and stakeholder management skills",
            },
            {
              skill:
                "Proficient knowledge of and experience in team building methods",
            },
            {
              skill:
                "Experience in working in Agile methods (eg. Scrum, Kanban)",
            },
            {
              skill: "Experience in working with remote teams",
            },
            {
              skill:
                "Very good self-organization, time management, analytical and problem-solving skills",
            },
            {
              skill:
                "Striving for continuous improvement of your own performance",
            },
            {
              skill:
                "Proactive, driven personality with high level of curiosity and self-development",
            },
            {
              skill: "Fluent English (C1+) in speaking and writing is a must",
            },
          ],
          additional: [
            {
              skill: "Product Management experience ",
            },
            {
              skill: "Startup experience",
            },
            {
              skill: "Familiarity with UX design and UX research",
            },
            {
              skill: "Practice in being an internal coach/trainer/speaker",
            },
            {
              skill:
                "Ability to find areas for improvement and proposing relevant solutions on an organizational level",
            },
          ],
          specifics: [
            {
              specific:
                "\n\nYou are the **owner of the pre-workshop and workshop process**, making sure the client and the team fully align on the product vision, strategy and roadmap.",
            },
            {
              specific:
                "\n\nDesigning and facilitating the **Product Discovery process of early product stages, such as prototypes and MVPs,** with international teams is your bread and butter.",
            },
            {
              specific:
                "\n\nBeing responsible for **guiding and coaching product teams** on their use of Agile practices and values.",
            },
            {
              specific: "\n\nGuiding teams to remove impediments.",
            },
            {
              specific:
                "\n\nYou are a living example of **Lean Startup enthusiast** :)",
            },
            {
              specific:
                "\n\nHelping the organization and our clients to understand and enact empirical product development.",
            },
            {
              specific:
                "\n\nYou enhance the product **oriented culture of collaboration and self-organization within Boldare team members**, applying best product development practices and tools (such as Design Thinking, Lean Startup, Scrum, Kanban, Liberating Structures, etc.)",
            },
          ],
          photo: "How_to_choose_a_software_development_company.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Trial day",
              details:
                "Works both ways. You can take a closer look on the way your team works real-time. We get to know you better :)",
            },
            {
              number: "05",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "6815ee5a-9cf8-5980-8182-30f6e876d61d",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/content-writer/",
      },
      frontmatter: {
        title: "Content Writer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "December 14, 2018",
          slug: "content-writer",
          type: "career",
          slugType: null,
          category: "business",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Content Writer",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 9000,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 7200,
                  max: 10800,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 6600,
                  max: 9900,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 6600,
                  max: 9900,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 10800,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/82FBF69D8A/apply/",
          banner: "/img/29738304237_4e5e7a8ab3_k.jpg",
          technical: [
            {
              skill:
                "At least 2 years of work experience as a Content Writer, Copywriter or in a similar role related to content creation",
            },
            {
              skill: "Portfolio of published articles in English",
            },
            {
              skill:
                "Familiarity with web publications, articles or e-books creation",
            },
            {
              skill: "Experience in software development industry",
            },
            {
              skill:
                "Practical knowledge of SEO copywriting (for articles and websites)",
            },
            {
              skill:
                "Knowledge of tools: Google Analytics, Google Search Console, SEMrush (or another similar SEO tool)",
            },
            {
              skill:
                "Ability to use reliable, up-to-date sources when creating content",
            },
            {
              skill: "Hands-on experience with Content Management Systems",
            },
            {
              skill:
                "Excellent skills in English, both in writing and editing(min C1)",
            },
            {
              skill:
                "Exceptional communication, self-organization and time management skills",
            },
            {
              skill: "Fluency in Polish",
            },
            {
              skill: "Flexibility and positive attitude towards team-work",
            },
            {
              skill:
                "Proactive, driven personality, striving for continuous improvement of own performance",
            },
          ],
          additional: [
            {
              skill: "backlink outreach experience",
            },
            {
              skill: "experience in SEM",
            },
            {
              skill: "experience in marketing campaign planning",
            },
            {
              skill:
                "experience in other areas of marketing, e.g. in social media",
            },
            {
              skill: "experience in cooperation with designers and developers",
            },
            {
              skill: "ability to manage budgets",
            },
          ],
          specifics: [
            {
              specific:
                "\n\nRegularly **producing and publishing various content types**, including blog posts,case studies and web copy",
            },
            {
              specific:
                "\n\nCollaborating with other teams to **create innovative content ideas**",
            },
            {
              specific:
                "\n\n**Collaborating with SEO agency** and/or freelancers",
            },
            {
              specific:
                "\n\n**Researching** industry-related topics and **recommending** new topics",
            },
            {
              specific: "\n\nUpdating website content as needed",
            },
            {
              specific:
                "\n\nCoordinating the work of article authors, external translators and native English-speaking editors to ensure the content is published on time, in accordance with content calendar",
            },
            {
              specific:
                "\n\n**Measuring** the results of activities and **creating metrics**",
            },
            {
              specific:
                "\n\nPractical application of **SEO principles** - creating interesting, substantive texts that are also SEO compliant",
            },
            {
              specific:
                "\n\n**Working hand in hand with other Content Writer,** the marketing team and other teams",
            },
          ],
          photo: "/img/team-in-the-office-kitchen.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max 72h. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Recruitment Task",
              details: "Don't worry, it's just a double-check of your skills",
            },
            {
              number: "03",
              step: "Let's talk!",
              details:
                "Time to get to know each other better! The recruitment interview is usually online for our both safety. You can expect honest and extensive feedback from our team in 7 days (or even sooner).",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "b0097065-177a-553d-a32b-2c5a31bd9f8b",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/customer-success-guide/",
      },
      frontmatter: {
        title: "Customer Success Guide",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "December 09, 2018",
          slug: "customer-success-guide",
          type: "career",
          slugType: null,
          category: "product",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Customer Success Guide",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 10450,
                },
                {
                  level: "senior",
                  min: 8700,
                  max: 15850,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 10450,
                },
                {
                  level: "senior",
                  min: 8700,
                  max: 15850,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 10450,
                },
                {
                  level: "senior",
                  min: 8700,
                  max: 15850,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 10450,
                },
                {
                  level: "senior",
                  min: 8700,
                  max: 15850,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 10450,
                },
                {
                  level: "senior",
                  min: 8700,
                  max: 15850,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/50E1946447/apply/",
          banner: "How_much_does_it_cost_to_outsource_software_development.jpg",
          technical: [
            {
              skill:
                "3+ years of working experience in Business Development, Account Management or other similar customer-oriented roles in the IT industry",
            },
            {
              skill: "Exceptional communication and interpersonal skills",
            },
            {
              skill:
                "Strong negotiations skills focused on successful deal-closing",
            },
            {
              skill:
                "Experience in building long-lasting business relationships",
            },
            {
              skill: "Familiarity with various sales processes",
            },
            {
              skill: "Hands-on experience with CRM (HubSpot, Salesforce)",
            },
            {
              skill:
                "Ability to think outside-the-box and create new solutions for different business challenges",
            },
            {
              skill: "Assertive attitude in contact with clients",
            },
            {
              skill: "Experience/Practice in working with scrum teams",
            },
            {
              skill: "Excellent command of written and spoken English & Polish",
            },
          ],
          additional: [
            {
              skill:
                "Proven record of working in the software consulting industry",
            },
            {
              skill:
                "Sufficient technical knowledge to discuss project-related issues with clients",
            },
            {
              skill: "Experience with an agile working environment",
            },
            {
              skill:
                "Ability to get a clear understanding of clients’ businesses and the benefits from our services offered to them",
            },
            {
              skill: "Established professional network in the EU/UK/US markets",
            },
          ],
          specifics: [
            {
              specific:
                "**Becoming a member of the Customer Success Team** responsible for building win-win partnerships with our large extended Clients, ensuring world-class software service standards and maximizing value delivered to the customer through product discovery and product development.",
            },
            {
              specific:
                "Responding to **customer’s product needs** in a timely fashion, providing them with relevant and engaging sales materials while working hand in hand with the Growth Team.",
            },
            {
              specific:
                "Conducting introductory calls with potential customers to **explain to them our holistic approach** to product development and to present Boldare’s unique value proposition.",
            },
            {
              specific:
                "**Preparing sales offers and negotiating contracts** with the existing customers and upselling Boldare’s services to the current customers.",
            },
            {
              specific:
                "**Collaborating with Scrum Masters** to execute innovative, yet the most effective customer experience strategies.",
            },
            {
              specific:
                "Providing exceptional **onboarding experience to the new customers.**",
            },
            {
              specific:
                "**Building and nurturing relationships** with all previous and current customers for maintaining high retention and advocacy results.",
            },
          ],
          photo: "How_to_choose_a_software_development_company.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max 72h. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call ",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps.",
            },
            {
              number: "03",
              step: "Let's talk!",
              details:
                "Time to get to know each other better! The recruitment interview is usually online for our both safety. You can expect honest and extensive feedback from our team in 7 days (or even sooner).",
            },
            {
              number: "04",
              step: "Trial day",
              details:
                "Works both ways. You can take a closer look on the way your team works real-time. We get to know you better :)",
            },
            {
              number: "05",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "783c1b22-7f0a-5ba3-8594-113dfec0e1e3",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/it-recruitment-specialist/",
      },
      frontmatter: {
        title: "IT Recruitment Specialist",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "March 09, 2021",
          slug: "it-recruitment-specialist",
          type: "career",
          slugType: null,
          category: "business",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "IT Recruitment Specialist",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 5600,
                  max: 9400,
                },
                {
                  level: "senior",
                  min: 9400,
                  max: 14800,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 5600,
                  max: 8500,
                },
                {
                  level: "senior",
                  min: 8500,
                  max: 12300,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 6700,
                  max: 10200,
                },
                {
                  level: "senior",
                  min: 10200,
                  max: 14800,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 6100,
                  max: 9400,
                },
                {
                  level: "senior",
                  min: 9400,
                  max: 13600,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 6100,
                  max: 9400,
                },
                {
                  level: "senior",
                  min: 9400,
                  max: 13600,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/3EF1E7EC3C/apply/",
          banner: "/img/43767019485_b0064eff44_k.jpg",
          technical: [
            {
              skill: "3+ years of commercial experience in IT recruitment",
            },
            {
              skill:
                "Experience in outsourcing/cooperating with HR agencies dedicated to IT",
            },
            {
              skill:
                "Rich know-how on conducting effective recruitment and selection processes",
            },
            {
              skill: "Practice in assessment tools for selection processes",
            },
            {
              skill: "Headhunting experience",
            },
            {
              skill: "Knowledge of tools and methods used in direct search",
            },
            {
              skill: "Outstanding active listening skills",
            },
            {
              skill:
                "Exceptional communication, self-organization and time management skills",
            },
            {
              skill:
                "Natural ease in building relationships with candidates and team members",
            },
            {
              skill: "Empathy and the ability to recognize candidates' needs",
            },
            {
              skill:
                "Very good analytical skills and ability to critically assess the situation",
            },
            {
              skill:
                "Proactive, driven personality, striving for continuous improvement of your own performance",
            },
            {
              skill:
                "Willingness to measure the efficiency of the selection process and to their continuous improvement",
            },
            {
              skill:
                "Fluency in English and Polish to easily communicate with candidates",
            },
          ],
          additional: null,
          specifics: [
            {
              specific:
                "\n\nYou’ll be responsible for conducting selection processes to **hire the best and culturally matched talents**",
            },
            {
              specific:
                "\n\nA **direct impact** on selection processes at Boldare and **building exceptional candidate experience**",
            },
            {
              specific:
                "\n\nWorking with **modern, comfortable tools** for communication and candidate selection (you can forget about candidate databases in Excel)",
            },
            {
              specific:
                "\n\nYou'll joint forces with a highly skilled Talent Selection team, eager to share their knowledge and best practices",
            },
            {
              specific:
                "\n\nYou’re the one to **work closely with representatives of other teams** to meet their recruitment needs",
            },
            {
              specific:
                "\n\nYou'll i**dentify new opportunities** to cover the organization’s needs regarding current hiring policy",
            },
          ],
          photo: "/img/Team_work_-_Boldare_Boards.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                " We just wanna catch you for 15 minutes to ask basic questions and set the next steps.",
            },
            {
              number: "03",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Short live assessment",
              details:
                "Let us see you in action during a few hours practical assessment (online or in our office) :) ",
            },
            {
              number: "05",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "79e524ec-dd51-523b-a1b6-e7961d59ce9f",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/javascript-developer/",
      },
      frontmatter: {
        title: "Javascript Developer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 05, 2018",
          slug: "javascript-developer",
          type: "career",
          slugType: null,
          category: "development",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Javascript Developer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/469951256B/apply/",
          banner: "/img/team-meeting.jpg",
          technical: [
            {
              skill: "2+ years of experience in developing web applications",
            },
            {
              skill: "Knowledge of JS framework: Vue.js or React",
            },
            {
              skill: "Practice in Typescript",
            },
            {
              skill: "Knowledge of ES6+ syntax",
            },
            {
              skill: "Familiarity with one of CSS preprocessors (i.e. Sass)",
            },
            {
              skill:
                "Experience with module bundlers (i.e. Webpack/Browserify)",
            },
            {
              skill:
                "Proficient understanding of code versioning tools (Git preferably)",
            },
            {
              skill: "command of English and Polish on B2+ level",
            },
          ],
          additional: [
            {
              skill: "experience in creating complex solutions",
            },
            {
              skill:
                "good understanding of design patterns important to API-driven apps",
            },
            {
              skill: "Experience with Docker",
            },
            {
              skill:
                "some experience in Continuous Integration (i.e. Travis CI/CircleCI/Jenkins)",
            },
          ],
          specifics: [
            {
              specific:
                "You are about to join **+ 30 talented and savvy JavaScript Developers** to build well-designed, user-centered apps for our foreign clients.",
            },
            {
              specific:
                "You'll be working **hand in hand with the client’s team** without unnecessary proxies.",
            },
            {
              specific:
                "Developing complex products in an **“A-Z manner”** is what you desire (we’re not code-monkeys!). You are willing to propose outcomes apart from just implementing them.",
            },
            {
              specific:
                "You’re excited about **working closely with** Product Designers, Backend Developers, Scrum Masters and QAs (they are your new best friends now!).",
            },
            {
              specific:
                "**True Scrum and Agile** **methodologies **is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re the **planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "**Code review and using best practices (TDD, BDD etc.)** are the habits you cannot live without.",
            },
            {
              specific:
                "You’ll have the **chance to create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "/img/37550431180_93a34a296d_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 24h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "0f26b6ae-de1e-5302-88de-f0f62006c6f0",
    },
  },

  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/node-js-developer/",
      },
      frontmatter: {
        title: "Node.js Developer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "April 30, 2019",
          slug: "node-developer",
          type: "career",
          slugType: null,
          category: "development",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Node.js Developer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/0340FB1B55/apply/",
          banner: "img/29737632997_e689d7b00a_k.jpg",
          technical: [
            {
              skill: "2+ years of experience in developing JS applications",
            },
            {
              skill:
                "Advanced knowledge of one of Node.js frameworks like eg.: Express, NestJS, Marble.js",
            },
            {
              skill: "Practice in TypeScript",
            },
            {
              skill:
                "Knowledge of SQL (preferably Postgres) or NoSQL database (preferably MongoDB)",
            },
            {
              skill: "Strong knowledge of REST API design",
            },
            {
              skill:
                "Good experience with one of the testing tools like Jest, Jasmine, Mocha, or Chai",
            },
            {
              skill: "Knowledge of ESNext",
            },
            {
              skill: "Experience with Docker",
            },
            {
              skill:
                "Experience in Continuous Integration (i.e. Travis CI/CircleCI/Jenkins)",
            },
            {
              skill:
                "Proficient understanding of code versioning tools (Git preferably)",
            },
            {
              skill: "Command of English and Polish on B2+ level",
            },
          ],
          additional: [
            {
              skill:
                "Good understanding of design patterns important to API-driven apps",
            },
            {
              skill: "Knowledge of AWS",
            },
            {
              skill:
                "Knowledge of Agile development methodologies (Scrum preferably)",
            },
            {
              skill: "Experience with WebSockets protocol",
            },
            {
              skill:
                "Experience with Reactive or Functional programming paradigms",
            },
            {
              skill: "Experience with microservice architecture",
            },
          ],
          specifics: [
            {
              specific:
                "You are about to join **+ 30 talented and savvy JavaScript Developers** to build well-designed, user-centered apps for our foreign clients.",
            },
            {
              specific:
                "You’ll be working hand in hand with the **client’s team without unnecessary proxies.**",
            },
            {
              specific:
                "Developing complex products in an **“A-Z manner”** is what you desire (we’re not code-monkeys!). You are willing to propose outcomes apart from just implementing them.",
            },
            {
              specific:
                "You’re excited about working closely with **Product Designers, Frontend Developers, Scrum Masters and QAs** (they are your new best friends now!).",
            },
            {
              specific:
                "**True Scrum and Agile** methodologies is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re the **planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "**Code review and using best practices** (TDD, BDD etc.) are the habits you cannot live without.",
            },
            {
              specific:
                "You’ll have the chance to **create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "img/43766365695_b0fb2815aa_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 24h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps.",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Time to get to know each other better! The recruitment interview is usually online for our both safety. You can expect honest and extensive feedback from our team in 7 days (or even sooner).",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "658a9768-f13d-5331-9e24-b7ae23c7a584",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/product-strategist/",
      },
      frontmatter: {
        title: "Product Strategist",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "June 24, 2021",
          slug: "product-strategist",
          type: "career",
          slugType: null,
          category: "product",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Product Strategist",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "senior",
                  min: 14000,
                  max: 22000,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "senior",
                  min: 14000,
                  max: 22000,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "senior",
                  min: 14000,
                  max: 22000,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "senior",
                  min: 14000,
                  max: 22000,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "senior",
                  min: 14000,
                  max: 22000,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/1D06A76905/apply/",
          banner: "/img/agile_budgeting_planning.jpg",
          technical: [
            {
              skill:
                "Practical experience in the same or similar role (Product Strategist, Product Owner, Product Manager, etc.)",
            },
            {
              skill:
                "Excellent knowledge of specifics of a digital products, including product phazes, Lean Startup methodology, Product Life Cycle",
            },
            {
              skill:
                "Ability to build product recommendations based on data; talent to read and analyze data and product metrics",
            },
            {
              skill: "Knowledge of Product Discovery process",
            },
            {
              skill:
                "Practical experience in using business models (frameworks: Pichler, Strategyzer, etc.)",
            },
            {
              skill:
                "Ability to define and plan your work based on business goals",
            },
            {
              skill: "Ability to build hypotheses and their validation",
            },
            {
              skill: "Ability to design experiments",
            },
            {
              skill:
                "Experience in agile environment and agile frameworks/methodologies (e.g. Scrum, Design Thinking)",
            },
            {
              skill:
                "Ability to research product users (creating personas, user stories)",
            },
            {
              skill:
                "Fluency in English and Polish (min. C1) to easily communicate with clients and coworkers",
            },
            {
              skill: "Assertiveness and ability to challenge the status quo",
            },
            {
              skill: "Ability to make quick and firm decisions",
            },
          ],
          additional: [
            {
              skill:
                "Experience with working in interdisciplinary teams in a startup-like atmosphere",
            },
            {
              skill: "Experience in product education for teams or companies",
            },
            {
              skill: "Experience in competitors analysis",
            },
          ],
          specifics: [
            {
              specific:
                "\n\nyou’ll be bringing new digital products to life - **collaborating with multidisciplinary teams of designers**, developers and other talented product people",
            },
            {
              specific:
                "\n\nworking closely to our teams and clients,you’ll be **validating** product ideas, **finding** market opportunities, **designing** solutions, **challenging** assumptions, running experiments, presenting to stakeholders, and collaborating with the full product team to make new products a reality",
            },
            {
              specific:
                "\n\nyou will **help make decisions on product direction** and stand up for creating value for both clients and their customers",
            },
            {
              specific:
                "\n\nyou’ll be **creating and delivering accurate**, high-quality, thoughtfully designed analyses to address operational and strategic questions",
            },
            {
              specific:
                "\n\nyou will be providing forward-looking insights and propose **recommendations**",
            },
            {
              specific:
                "\n\n**True Scrum and Agile methodologies** are what you embrace on a daily basis",
            },
          ],
          photo: "/img/49496437912_dcf2e0b0d4_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume ",
              details:
                "It’s going to be verified in max. 72h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let's talk",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Trial Day",
              details:
                "Don’t worry, it’s just a double-check of your skills. You can expect an honest and extensive feedback from our team.",
            },
            {
              number: "05",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "2137e034-dd2e-5bfb-9139-89d377352ee5",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/prototype-mvp-designer/",
      },
      frontmatter: {
        title: "Prototype/MVP Designer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "May 17, 2021",
          slug: "prototype/mvp - designer",
          type: "career",
          slugType: null,
          category: "design",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Prototype/MVP Designer",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 7800,
                  max: 14100,
                },
                {
                  level: "senior",
                  min: 14100,
                  max: 20400,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 7800,
                  max: 11900,
                },
                {
                  level: "senior",
                  min: 11900,
                  max: 17000,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 8600,
                  max: 13000,
                },
                {
                  level: "senior",
                  min: 13100,
                  max: 18400,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9400,
                  max: 14300,
                },
                {
                  level: "senior",
                  min: 14000,
                  max: 20400,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 8600,
                  max: 13100,
                },
                {
                  level: "senior",
                  min: 13000,
                  max: 18700,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/C8CC146B0B/apply/",
          banner: "/img/Boldare-office-stage-Pawel.jpg",
          technical: [
            {
              skill:
                "Experience in product discovery process and conducting workshops for clients",
            },
            {
              skill: "Practice in building products based on business goals",
            },
            {
              skill:
                "Experience in validating problems & solutions; solving users’ problems",
            },
            {
              skill:
                "Practical knowledge of building and validating hypotheses",
            },
            {
              skill:
                "Experience in preparing and conducting user testing/interviews",
            },
            {
              skill:
                "Understanding of working in prototype and MVP product phases",
            },
            {
              skill: "Knowledge of Lean Startup framework",
            },
            {
              skill: "Ability to make quick and conclusive decisions",
            },
            {
              skill: "Excellent communication and organizational skills",
            },
            {
              skill:
                "Fluency in English and Polish (B2+ level) to easily communicate with clients",
            },
          ],
          additional: [
            {
              skill:
                "Experience with working in interdisciplinary teams in a startup-like atmosphere",
            },
            {
              skill: "Knowledge of current design trends",
            },
            {
              skill:
                "Significant knowledge of software, good practices, and patterns in graphic design",
            },
          ],
          specifics: [
            {
              specific:
                "You'll be **designing for real** - no fuss, logical process and only foreign clients.",
            },
            {
              specific:
                "**Collaborating with various internal development teams** to satisfy product business strategies and end-users' needs.",
            },
            {
              specific:
                "**Help our clients shape digital reality around them on different stages.** Whether it's an MVP, prototype, product-market fit, or scaling, for each of the stages we’ve developed a dedicated working environment.",
            },
            {
              specific:
                "**Logic information architectures, compelling user flows, low and hi-fidelity wireframes and prototypes** - these are the basic terms in your dictionary.",
            },
            {
              specific:
                "**Testing the effects of your team's work** during manual acceptance tests is like a cherry on top for you while releasing a world-class design.",
            },
            {
              specific:
                "It's time to become a part of our **community-focused initiatives** i.e. Designers Coffee!",
            },
          ],
          photo: "/img/Boldare-office-conference-room.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume and portfolio",
              details:
                "It’s going to be verified by our designers. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "03",
              step: "Trial day",
              details:
                "Works both ways. You can take a closer look on the way your team works real-time. We get to know you better :)",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "bcf4a2f4-db5f-5798-976a-db19a859e275",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/qa-business-analyst/",
      },
      frontmatter: {
        title: "QA Business Analyst",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 19, 2018",
          slug: "qa-business-analyst",
          type: "career",
          slugType: null,
          category: "qualityAssurance",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "QA Business Analyst",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/D586335ABE/apply/",
          banner: "/img/eam-sitting-in-office-room.jpg",
          technical: [
            {
              skill: "Understanding of BDD ",
            },
            {
              skill: "experience with manual testing",
            },
            {
              skill: "knowledge of Gherkin",
            },
            {
              skill: "practice in writing clean bug reports and test plans",
            },
            {
              skill:
                "experience with defining and refining requirements, and identifying business and product goals",
            },
            {
              skill: "Experience with creating use case diagrams",
            },
            {
              skill:
                "practice in planning and leading product discovery workshops",
            },
            {
              skill:
                "Practice in creating product roadmaps, user story mapping, product vision board, building a product persona",
            },
            {
              skill:
                "Ability to adapt to project needs, select right techniques depending on current priorities",
            },
            {
              skill: "Great communication and facilitation skills",
            },
            {
              skill: "command of English and Polish on B2+ level",
            },
          ],
          additional: [
            {
              skill:
                "Practice with techniques like impact mapping, event storming, business model canvas, etc.",
            },
            {
              skill:
                "understanding of UX principles and knowing how to test usability/accessibility",
            },
            {
              skill: "practice in using test management tools ",
            },
          ],
          specifics: [
            {
              specific:
                "You will **help us deliver the best possible quality of products** in accordance to users needs and business goals of our foreign clients.",
            },
            {
              specific:
                "You will be **effectively capturing business requirements** to facilitate the design and development stages.",
            },
            {
              specific:
                "You are able to **envision goals** that software should accomplish.",
            },
            {
              specific:
                "Creating **compelling user stories** and making sure software features address business problems.",
            },
            {
              specific:
                "You’re excited about **working closely with** Product Designers, Developers and Scrum Masters (they are your new best friends now!).",
            },
            {
              specific:
                "**True Scrum and Agile methodologies** is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re the **planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "You’ll have the chance to **create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "/img/development-team-discussion.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "4d0ac2e8-38c2-5488-84f2-74ac79bbb3a4",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/react-native-developer-1/",
      },
      frontmatter: {
        title: "React Native Developer",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "April 23, 2021",
          slug: "react-native-developer-1",
          type: "career",
          slugType: null,
          category: "development",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "React Native Developer",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 9000,
                  max: 15700,
                },
                {
                  level: "senior",
                  min: 15700,
                  max: 22500,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/8271601785/apply/",
          banner: "/img/Code_audit.jpg",
          technical: [
            {
              skill: "2+ years of experience in developing web applications",
            },
            {
              skill:
                "Have knowledge of JS frameworks: React Native, React, Redux",
            },
            {
              skill: "Practice in Typescript",
            },
            {
              skill: "Knowledge of ES6+ syntax",
            },
            {
              skill: "Familiarity with one of CSS preprocessors (i.e. Sass)",
            },
            {
              skill:
                "Experience with module bundlers (i.e. Webpack/Browserify)",
            },
            {
              skill:
                "Proficient understanding of code versioning tools (Git preferably)",
            },
            {
              skill: "Command of English and Polish on B2+ level",
            },
          ],
          additional: [
            {
              skill: "Experience in creating complex solutions",
            },
            {
              skill:
                "Good understanding of design patterns important to API-driven apps",
            },
            {
              skill: "Experience with Docker",
            },
            {
              skill:
                "Some experience in Continuous Integration (i.e. Travis CI/CircleCI/Jenkins)",
            },
          ],
          specifics: [
            {
              specific:
                "You are about to join **+ 30 talented and savvy JavaScript Developers** to build well-designed, user-centered apps for our foreign clients.",
            },
            {
              specific:
                "You’ll be working **hand in hand with the client’s team** without unnecessary proxies.",
            },
            {
              specific:
                "Developing complex products in an **“A-Z manner”** is what you desire (we’re not code-monkeys!). You are willing to propose outcomes apart from just implementing them.",
            },
            {
              specific:
                "You’re excited about **working closely with** Product Designers, Backend Developers, Scrum Masters and QAs (they are your new best friends now!).",
            },
            {
              specific:
                "**True Scrum and Agile** **methodologies** is what you embrace on a daily basis.",
            },
            {
              specific:
                "You’re the **planner of your own work** - we’re self-managing teams.",
            },
            {
              specific:
                "**Code review and using best practices (TDD, BDD etc.)** are the habits you cannot live without.",
            },
            {
              specific:
                "You’ll have the **chance to create your own career path** (not a catch-phrase, let’s talk seriously!).",
            },
          ],
          photo: "/img/Team_work_-_Boldare_Boards.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified in max. 72h, one of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let’s talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start working together, you're in!",
            },
          ],
        },
      },
      id: "074c261a-831d-5d1f-beb8-334fab9af0be",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/sales-executive/",
      },
      frontmatter: {
        title: "Sales Executive",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "March 18, 2020",
          slug: "sales-executive",
          type: "career",
          slugType: null,
          category: "business",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Sales Executive",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "senior",
                  min: 10000,
                  max: 16000,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "senior",
                  min: 10000,
                  max: 16000,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "senior",
                  min: 10000,
                  max: 16000,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "senior",
                  min: 10000,
                  max: 16000,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "senior",
                  min: 10000,
                  max: 16000,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/B18A3D26F4/apply/",
          banner: "How_to_choose_a_software_development_company.jpg",
          technical: [
            {
              skill:
                "Minimum 4+ years of sales experience in the IT sector (or related)",
            },
            {
              skill: "Experience in B2B international sales ",
            },
            {
              skill:
                "Ability to build valuable, stable and lasting business relationships with clients thanks to your interpersonal skills",
            },
            {
              skill:
                "Ability to comprehensively analyze the situation, draw conclusions and transform them into solutions tailored to clients’ needs.",
            },
            {
              skill:
                "Drive to not only sell, but also to help customers achieve their business goals and consult solutions",
            },
            {
              skill: "Client-centered approach ",
            },
            {
              skill:
                "Motivation for constant development of your competences and share them with colleagues",
            },
            {
              skill:
                "Availability for remote work (business trips to clients included)",
            },
            {
              skill: "Fluency in English and Polish both spoken and written",
            },
            {
              skill:
                "You are focused on working with modern sales tools and metrics",
            },
          ],
          additional: [
            {
              skill: "Focus on teamwork over individual benefits",
            },
            {
              skill:
                "Proactivity and constant looking for improvements in sales processes, ",
            },
          ],
          specifics: [
            {
              specific:
                "**Work in a flat structure based on a holacratic system** (no managers, dispersed responsibility, full transparency).",
            },
            {
              specific:
                "Possibilities of co-creating **a unique** **sales process at a high level**, with an emphasis on exceptional customer experience",
            },
            {
              specific:
                "Frequent contacts and active participation in the sales process with our **international clients** - from acquiring leads, to closing deals and signing contracts, to handing over the project to the team",
            },
            {
              specific:
                "**Cooperation within interdisciplinary teams** responsible for preparing proposals for clients (Business Analysts, Developers, Designers working together)",
            },
            {
              specific:
                "**Work in a modern CRM environment** (Salesforce ecosystem) with sales tools such as funnels, journey maps, personas etc.",
            },
            {
              specific:
                "**Being ready for** openness to innovation, experimentation, improvement, teamwork and data-driven thinking",
            },
            {
              specific:
                "Possibilities of representing Boldare at **international meetings, trade fairs, conferences**",
            },
            {
              specific:
                "**Cooperation with mature companies and prospective tech startups** (eg. German pioneer in the renewable energy sector - Sonnen (Shell), Unifonic - the company with the largest UAE government financing in history, Virgin, BlaBlaCar, Bosh etc.)",
            },
          ],
          photo: "The_6_Biggest_challenges_of_software_outsourcing.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume ",
              details:
                "It’s going to be verified in max 72h. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "03",
              step: "Trial day",
              details:
                "Works both ways. You can take a closer look on the way your team works real-time. We get to know you better :)",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "e097c51d-4ab9-5dc5-821b-769ff99815ef",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/sales-executive-in-germany/",
      },
      frontmatter: {
        title: "Sales Executive (in Germany)",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "March 22, 2021",
          slug: "sales-executive-in-germany",
          type: "career",
          slugType: null,
          category: "business",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Sales Executive (in Germany)",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "senior",
                  min: 20000,
                  max: 32000,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/830B450382/apply/",
          banner: "How_to_choose_a_software_development_company.jpg",
          technical: [
            {
              skill:
                "Fluency in Polish, English and German (both spoken and written)",
            },
            {
              skill:
                "Minimum 4+ years of B2B international sales experience in the IT sector (or related)",
            },
            {
              skill:
                "Proficient knowledge of the German IT market (the residence in Germany is must have)",
            },
            {
              skill:
                "Ability to build valuable, stable and lasting business relationships with clients thanks to your interpersonal skills",
            },
            {
              skill: "Focus on teamwork over individual benefits",
            },
            {
              skill:
                "Ability to comprehensively analyze the situation, draw conclusions and transform them into solutions tailored to clients’ needs",
            },
            {
              skill:
                "Drive to not only sell, but also to help customers achieve their business goals and consult solutions; client-centered approach ",
            },
            {
              skill:
                "Motivation for constant development of your competences and share them with colleagues",
            },
            {
              skill:
                "Availability for remote work (business trips to clients included)",
            },
          ],
          additional: [
            {
              skill:
                "Proactivity and constant looking for improvements in sales processes",
            },
          ],
          specifics: [
            {
              specific:
                "Working in a flat structure based on a holacratic system (**no managers, dispersed leadership and autonomy** in decision-making, full transparency)",
            },
            {
              specific:
                "Possibilities of co-creating **a unique** **sales process at a high level**, with an emphasis on exceptional customer experience",
            },
            {
              specific:
                "Frequent contacts and active participation in the sales process with our **international clients** - from acquiring leads, to closing deals and signing contracts, to handing over the project to the team",
            },
            {
              specific:
                "**Cooperation within interdisciplinary teams** responsible for preparing proposals for clients (Business Analysts, Developers, Designers working together)",
            },
            {
              specific:
                "**Work in a modern CRM environment** (Salesforce ecosystem) with sales tools such as funnels, journey maps, personas etc.",
            },
            {
              specific:
                "**Being ready for** openness to innovation, experimentation, improvement, teamwork and data-driven thinking",
            },
            {
              specific:
                "Possibilities of representing Boldare at **international meetings, trade fairs, conferences**",
            },
            {
              specific:
                "**Cooperation with mature companies and prospective tech startups** (eg. German pioneer in the renewable energy sector - Sonnen (Shell), Unifonic - the company with the largest UAE government financing in history, Virgin, BlaBlaCar, Bosh etc.)",
            },
          ],
          photo: "The_6_Biggest_challenges_of_software_outsourcing.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume ",
              details:
                "It’s going to be verified in max 72h. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "03",
              step: "Trial day",
              details:
                "Works both ways. You can take a closer look on the way your team works real-time. We get to know you better :)",
            },
            {
              number: "04",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "9292ad32-6d6e-577f-b0a5-9506bb43afc4",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/scrum-master/",
      },
      frontmatter: {
        title: "Scrum Master",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you! Ready to take a leap?** If you feel like you’re a perfect match to a team that designs and develops digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "November 19, 2018",
          slug: "scrum-master",
          type: "career",
          slugType: null,
          category: "product",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Scrum Master",
          cities: [
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 7200,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 12000,
                  max: 18700,
                },
              ],
            },
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 7200,
                  max: 10900,
                },
                {
                  level: "senior",
                  min: 10900,
                  max: 15600,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 8600,
                  max: 13100,
                },
                {
                  level: "senior",
                  min: 13100,
                  max: 18700,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 7900,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 12000,
                  max: 17200,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 7900,
                  max: 12000,
                },
                {
                  level: "senior",
                  min: 12000,
                  max: 17200,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/A19046990F/apply/",
          banner: "/img/team-during-workshop-meeting.jpg",
          technical: [
            {
              skill:
                "3+ years of experience as a Scrum Master or in project management role",
            },
            {
              skill:
                "exceptional communication, self-organization, and time management skills",
            },
            {
              skill:
                "very good analytical and problem-solving skills, client facing and stakeholder management",
            },
            {
              skill:
                "proactive, driven personality and will to make the difference on an organization level",
            },
            {
              skill:
                "high level of commitment to improve software development using Agile methodologies",
            },
            {
              skill:
                "striving for continuous improvement of your own performance",
            },
            {
              skill:
                "knowledge in other management methods and techniques (i.e. Kanban, Scaled Scrum, Nexus, XP, Lean, SAFe, DSDM)",
            },
            {
              skill:
                "proficient knowledge and experience in team building methods",
            },
            {
              skill: "experience in working with remote teams",
            },
            {
              skill: "Fluent English in speaking and writing is a must",
            },
          ],
          additional: [
            {
              skill: "experience in product discovery in Agile/Lean approach",
            },
            {
              skill: "practice in being an internal coach/trainer/speaker",
            },
            {
              skill:
                "skills proven by a Scrum Master certificate (i.e. PSM, PSPO,PSL)",
            },
            {
              skill:
                "ability to find areas for improvement and proposing relevant solutions on an organizational level",
            },
          ],
          specifics: [
            {
              specific:
                "You're responsible for **guiding and coaching Scrum Teams** on their use of Agile practices and values.",
            },
            {
              specific:
                "You're the one to **work closely with client-side Product Owners** to maximize product business value.",
            },
            {
              specific:
                "Organizing and facilitating **Scrum-related meetings with international teams\n** is your bread and butter.",
            },
            {
              specific: "You guide the teams on removing impediments.",
            },
            {
              specific:
                "Helping the organization and our clients to **understand and enact empirical product development.**",
            },
            {
              specific:
                "You **enhance culture of collaboration and self-organization** within Boldare team members.",
            },
          ],
          photo: "/img/team-in-the-office-kitchen.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Let's talk!",
              details:
                "Let’s talk! Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "04",
              step: "Short live assessment",
              details:
                "Let us see you in action during a few hours practical assessment (online or in our office) :) ",
            },
            {
              number: "05",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "5e28c904-baf5-5df4-a295-0673cb862c45",
    },
  },
  {
    node: {
      excerpt: "",
      fields: {
        slug: "/career/junior-social-media-specialist/",
      },
      frontmatter: {
        title: "Social Media Specialist",
        order: null,
        content: null,
        job: null,
        photo: null,
        slug: null,
        cover: null,
        lead: "**We’ve been expecting you!** Ready to take a leap? If you feel like you’re a perfect match to the team that helps growth our organisation and markets digital products for forward-thinking industries like renewable energy, sharing economy services, automotive or mobile marketing (all around the globe!), take this opportunity and apply!",
        templateKey: "job-offer-page",
        isNewWork: null,
        isNewNormal: null,
        service: null,
        settings: {
          date: "October 30, 2019",
          slug: "sm-specialist",
          type: "career",
          slugType: null,
          category: "business",
          additionalCategories: null,
          url: null,
        },
        author: null,
        authorAdditional: null,
        box: null,
        mainContent: {
          archived: false,
          name: "Social Media Specialist",
          cities: [
            {
              city: "gliwice",
              salaries: [
                {
                  level: "medior",
                  min: 5000,
                  max: 7500,
                },
                {
                  level: "senior",
                  min: 7500,
                  max: 11000,
                },
              ],
            },
            {
              city: "warszawa",
              salaries: [
                {
                  level: "medior",
                  min: 6000,
                  max: 9000,
                },
                {
                  level: "senior",
                  min: 9000,
                  max: 13000,
                },
              ],
            },
            {
              city: "wroclaw",
              salaries: [
                {
                  level: "medior",
                  min: 5500,
                  max: 8000,
                },
                {
                  level: "senior",
                  min: 8000,
                  max: 12000,
                },
              ],
            },
            {
              city: "krakow",
              salaries: [
                {
                  level: "medior",
                  min: 5500,
                  max: 8000,
                },
                {
                  level: "senior",
                  min: 8000,
                  max: 12000,
                },
              ],
            },
            {
              city: "remote",
              salaries: [
                {
                  level: "medior",
                  min: 5000,
                  max: 9000,
                },
                {
                  level: "senior",
                  min: 9000,
                  max: 13000,
                },
              ],
            },
          ],
          apply: "https://apply.workable.com/boldare/j/5A19453032/apply/",
          banner: "img/team-in-the-office-kitchen.jpg",
          technical: [
            {
              skill:
                "At least 2 years of professional experience in managing social media platforms and running paid campaigns for a B2B or B2C brand",
            },
            {
              skill: "Excellent writing skills in English",
            },
            {
              skill:
                "Fluency in Polish (B2+ level) to easily communicate with the team",
            },
            {
              skill:
                "A huge desire to constantly develop your skills and an open mind to learn and adapt",
            },
            {
              skill:
                "Proactive, driven personality and strong organizational, analytical and problem-solving skills",
            },
            {
              skill:
                "Practical knowledge of managing, measuring and automating social media with tools (Hootsuite, Google Analytics etc.)",
            },
          ],
          additional: [
            {
              skill: "Knowing Google Ads (AdWords);",
            },
            {
              skill: "A social media agency working background;",
            },
            {
              skill: "Interest in business news and technology trends.",
            },
          ],
          specifics: [
            {
              specific:
                "You’ll join an **interdisciplinary Recruitment Marketing Team** to help us boost our social media marketing efforts",
            },
            {
              specific:
                "**Managing social media platforms** according to the social media strategy (such as Twitter, LinkedIn, Facebook, Instagram, YouTube) is not a mystery for you;",
            },
            {
              specific:
                "You feel comfortable in writing and **publishing content on social media channels** (managing content planning with external tools like Hootsuite);",
            },
            {
              specific:
                "Our philosophy of leading long-term **social media campaigns** matches yours;",
            },
            {
              specific:
                "You’ll **keep an eye on Boldare’s brand exposure** on social media platforms and propose new ways to improve it;",
            },
            {
              specific:
                "**Building a community** of followers on each platform is what drives you forward;",
            },
            {
              specific:
                "Setting up and reporting general **social media metrics**",
            },
            {
              specific:
                "You’ll be analyzing and **recommending improvements** to boost Boldare’s social media channels.",
            },
            {
              specific:
                "**Collaborating with social media agency** (setting up goals, budgets and plans; monitoring and analyzing collaboration)",
            },
          ],
          photo: "img/43766365695_b0fb2815aa_k.jpg",
          steps: [
            {
              number: "01",
              step: "Send your resume",
              details:
                "It’s going to be verified. One of our recruiters takes care of you during the whole process and answers all your questions.",
            },
            {
              number: "02",
              step: "Short phone call",
              details:
                "We just wanna catch you for 15 minutes to ask basic questions and set the next steps",
            },
            {
              number: "03",
              step: "Recruitment task",
              details:
                "Don’t worry, it’s just a double-check of your skills. You can expect an honest and extensive feedback from our specialists.",
            },
            {
              number: "04",
              step: "Let’s talk! ",
              details:
                "Time to get to know each other better! The recruitment interview is usually online for our both safety",
            },
            {
              number: "05",
              step: "Offer? Accepted!",
              details:
                "Let’s fire up the engines and start to work together, you're in!",
            },
          ],
        },
      },
      id: "8071ed16-b69d-51d9-b51e-76e7f03709c6",
    },
  },
];
