export const config = {
  // GENERAL
  siteUrl: "https://www.products.boldare.com",
  pathPrefix: "/",
  siteLogo: "/assets/logo-menu.svg", // Logo used for SEO and manifest.
  // CMS
  siteTitle: "Boldare Products",
  siteTitleAlt: "Boldare Products",
  siteDescription: `Let's make products you can be proud of!`,
  siteCover: "",

  // THEME
  themeColor: "#f1d624",
  backgroundColor: "#ffffff",
  // APPLICATIONS
  // googleTagManagerID: 'GTM-NJN9MVR',
  disqusShortname: "boldare",
  siteFBAppID: "",
  facebook: "https://www.facebook.com/boldarecom/",
  linkedin: "https://www.linkedin.com/company/boldarecom/",
  twitter: "https://twitter.com/boldarecom",
  youtube: "https://www.youtube.com/channel/UCOTEkbD0MSAmBzGFNrnRaxQ",
  instagram: "https://www.instagram.com/boldarecom",
  awwwards: "https://www.awwwards.com/boldare",
  clutch: "https://clutch.co/profile/boldare",
  dribbble: "https://dribbble.com/Boldare",
  slideshare: "https://www.slideshare.net/Boldare",
  goodfirms: "https://www.goodfirms.co/company/boldare",
  behance: "https://www.behance.net/boldarecom",
  gda: "https://www.boldare.com/blog/we-won-german-design-awards-2021/",
};
