import gsap from "gsap";

export const showButton = (
  element: Element | HTMLHeadingElement,
  containerEl: HTMLElement | HTMLDivElement,
  options: {
    markers?: boolean;
    typeStart?: string;
    typeEnd?: string;
    removeStart?: string;
    removeEnd?: string;
  } = { markers: false }
) => {
  return gsap
    .timeline()
    .fromTo(
      // hide btn
      element,
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: containerEl,
          scrub: 0.5,
          markers: options?.markers || false,
          start: options?.removeStart || "center 0%",
          end: options?.removeEnd || "+=45%",
        },
      }
    )
    .fromTo(
      // show btn
      element,
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        scrollTrigger: {
          trigger: containerEl,
          scrub: 0.5,
          markers: options?.markers || false,
          start: options?.typeStart || "center 55%",
          end: options?.typeEnd || "+=10%",
        },
      }
    );
};
