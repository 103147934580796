import React from "react";
import Paragraph from "components/Paragraph";
import IconSVG from "components/IconSVG/IconSVG";
import { classNames } from "helpers";

import "./Button.scss";

type ButtonProps = {
  additionalClass?: string;
  ariaName?: string;
  arrowColor?: string;
  borderColor?: string;
  children?: React.ReactNode;
  circleText?: string;
  circleSize?: string;
  circleIconType?: string;
  color?: string;
  hover?: boolean;
  id?: string;
  loading?: boolean;
  onClick?: () => void;
  order?: string;
  resize?: boolean;
  tag?: React.FC | null;
  transparent?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  underline?: boolean;
};

const Button = React.forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      additionalClass = "",
      ariaName = "button",
      arrowColor = "",
      borderColor = "",
      children,
      circleText = "",
      circleSize = "",
      circleIconType = "",
      color = "",
      hover = false,
      loading = false,
      order = "primary",
      resize = false,
      tag: Tag,
      transparent = false,
      type = "button",
      underline = false,
      ...other
    }: ButtonProps,
    ref
  ) => {
    const circleIcon = circleIconType === "pause" ? "pause" : "play";
    const orderClass = order ? `button--${order}` : null;
    const buttonProps = {
      className: classNames(
        "button",
        orderClass,
        {
          "button--underline": underline,
          [`button--${color}`]: !transparent && color,
          [`button--hover-${color}`]: hover,
          [`button--trans`]: transparent,
          [`button--border-${borderColor}`]: borderColor,
        },
        additionalClass
      ),
      ...other,
    };

    const button = Tag ? (
      <Tag {...buttonProps}>{children}</Tag>
    ) : (
      <button type={type} {...buttonProps} aria-label={ariaName}>
        {loading ? (
          <span className={"button--loading"}>{children}</span>
        ) : (
          children
        )}
      </button>
    );

    return (
      <>
        {order === "circle" ? (
          <div
            ref={ref}
            className={classNames("button", orderClass, additionalClass)}
          >
            <span>
              {resize ? (
                <IconSVG
                  name={"resize"}
                  color={"mine-shaft"}
                  additionalClass={"resize-icon"}
                />
              ) : (
                <IconSVG
                  name={circleIcon}
                  color={"mine-shaft"}
                  additionalClass={"play-icon"}
                />
              )}
            </span>
            <IconSVG
              name={circleSize === "large" ? "circle-large" : "circle"}
              size={"huge"}
              color={"pigeon-post"}
              additionalClass={"circle"}
            />
            {circleText && <Paragraph color={color}>{circleText}</Paragraph>}
          </div>
        ) : (
          button
        )}
      </>
    );
  }
);

export default Button;
