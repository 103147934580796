import gsap from "gsap";
import { showText, textDatasetSelector } from "helpers/showText";
import { showButton } from "helpers/showButton";
import { mapAnimToTimeline } from "helpers/mapAnim";

export const enterSubsection = (
  sidebarContentContainer: HTMLDivElement,
  scrollContainer: HTMLDivElement,
  options: {
    typeStart?: string;
    typeEnd?: string;
    removeStart?: string;
    removeEnd?: string;
  } = {}
) => {
  const localSelector = gsap.utils.selector(sidebarContentContainer);
  const buttons = localSelector(".button");
  const paragraphs = localSelector(".paragraph");
  const headings = localSelector("h2");
  const labels = localSelector(".section-header__headline-label");
  return gsap
    .timeline({ paused: true })
    .add(
      mapAnimToTimeline(labels, (p) =>
        showText(p, scrollContainer, { markers: false })
      )
    )
    .add(
      mapAnimToTimeline(headings, (p) =>
        showText(p, scrollContainer, { markers: false })
      )
    )
    .add(
      mapAnimToTimeline(paragraphs, (p) =>
        showText(p, scrollContainer, {
          markers: false,
          textSelector: textDatasetSelector,
        })
      )
    )
    .add(showButton(buttons as any, scrollContainer, { markers: false }));
};
