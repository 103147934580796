import React, { useRef } from "react";
import { SectionHeader } from "../SectionHeader";
import Slider from "./subcomponents/Slider";

import "./EtudesSection.scss";

export const EtudesSection = () => {
  const etudesWrapper = useRef<HTMLDivElement | null>(null);

  return (
    <section
      className={"section-etudes section-box"}
      data-color="white"
      ref={etudesWrapper}
    >
      <div className="padding__container">
        <SectionHeader
          additionalClass={"section-etudes__heading"}
          color={"mine-shaft"}
          headingType={2}
          size={"big"}
          centered
        >
          Talk about your work with pride and a smile!
        </SectionHeader>
        <Slider />
      </div>
    </section>
  );
};
