import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import {
  HeroSection,
  IntroductionSection,
  AboutSection,
  EtudesSection,
  PortfolioSection,
  QuotesSection,
  JoinSection,
  FooterSection,
} from "./components";
import "./ForThePlanet.scss";
import { jobs } from "./data/jobs";

const ForThePlanet = () => {
  const videoPreviewRef = useRef<HTMLVideoElement>(null);
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(TextPlugin);
  gsap.defaults({ overwrite: "auto" });
  const mainTimeline = gsap.timeline();

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
      gsap.to(window, { duration: 0, scrollTo: 0 });
    };
  }, []);

  useLayoutEffect(() => {
    gsap.utils.toArray(".section-box").forEach((elem: any) => {
      const color = elem.getAttribute("data-color");
      ScrollTrigger.create({
        trigger: elem,
        start: "top 50%",
        end: "bottom 50%",
        onEnter: () => gsap.to("#section-about", { backgroundColor: color }),
        onLeave: () => gsap.to("#section-about", { backgroundColor: "white" }),
        onLeaveBack: () =>
          gsap.to("#section-about", { backgroundColor: "white" }),
        onEnterBack: () =>
          gsap.to("#section-about", { backgroundColor: color }),
      });
    });
    gsap.utils.toArray(".section-join").forEach((elem: any) => {
      ScrollTrigger.create({
        trigger: elem,
        start: "top 50%",
        end: "bottom",
        onEnter: () =>
          gsap.to(
            ".navbar .link.link__underline, .navbar__logo--img, .navbar__menu-item .link",
            { filter: "brightness(0.2)" }
          ),
        onLeave: () =>
          gsap.to(
            ".navbar .link.link__underline, .navbar__logo--img, .navbar__menu-item .link",
            { filter: "none" }
          ),
        onLeaveBack: () =>
          gsap.to(
            ".navbar .link.link__underline, .navbar__logo--img, .navbar__menu-item .link",
            { filter: "none" }
          ),
        onEnterBack: () =>
          gsap.to(
            ".navbar .link.link__underline, .navbar__logo--img, .navbar__menu-item .link",
            { filter: "none" }
          ),
      });
    });
    gsap.to(".indicator", {
      opacity: 0,
      scrollTrigger: {
        trigger: ".section-intro",
        scrub: true,
        end: "+=200px",
      },
    });
  }, []);

  return (
    <>
      <div className={"forThePlanet"} id={"forThePlanet-top"}>
        <main>
          <HeroSection
            mainTimeline={mainTimeline}
            videoPreviewRef={videoPreviewRef}
          />
          <IntroductionSection mainTimeline={mainTimeline} />
          <AboutSection
            mainTimeline={mainTimeline}
            videoPreviewRef={videoPreviewRef}
          />
          <div className="spacer" />
          <EtudesSection />
          <PortfolioSection />
          <QuotesSection />
          <JoinSection />
        </main>
      </div>
      <FooterSection jobs={jobs} />
    </>
  );
};

export default ForThePlanet;
