import React, { useRef, useLayoutEffect } from "react";
import { SectionHeader } from "../SectionHeader";
import Button from "components/Button";
import Link from "components/Button/Link/Link";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import planet1 from "assets/images/Planet_1.png";
import planet2 from "assets/images/Planet_2.png";
import planet3 from "assets/images/Planet_3.png";
import planet4 from "assets/images/Planet_4.png";
import planet5 from "assets/images/Planet_5.png";

import "./JoinSection.scss";

export const JoinSection = () => {
  gsap.registerPlugin(ScrollTrigger);

  const introWrapper = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!introWrapper.current) return;

    const sectionIntro = introWrapper.current;
    const mainCircle = introWrapper.current.querySelector(
      ".section-join .circle--main"
    );
    const circles = introWrapper.current.querySelector(
      ".section-join .box__container"
    );
    const label = introWrapper.current.querySelector(
      ".section-join .section-header__headline-label"
    );
    const heading = introWrapper.current.querySelector(
      ".section-join .heading"
    );
    const actionButton = introWrapper.current.querySelector(
      ".section-join .button"
    );
    const additionalPlanets = introWrapper.current.querySelector(
      ".section-join .additional-planet__container"
    );

    const mainTl = gsap.timeline({ defaults: { opacity: 1 } });
    const circlesTl = gsap.timeline();
    const elementsTl = gsap.timeline();

    const circlesArr = [mainCircle, circles, additionalPlanets];
    const elementsArr = [label, heading, actionButton];

    circlesTl.fromTo(
      circlesArr, // circles hide
      {
        opacity: 1,
        scale: 1,
      },
      {
        opacity: 0,
        scale: 0,
        scrollTrigger: {
          trigger: sectionIntro,
          toggleActions: "play none none reset",
        },
      }
    );

    elementsTl.fromTo(
      elementsArr, // text elements and button hide
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        y: 20,
        scrollTrigger: {
          trigger: sectionIntro,
          toggleActions: "play none none reset",
        },
      }
    );

    ScrollTrigger.create({
      // show all elements
      trigger: sectionIntro,
      start: "top 20%",
      toggleActions: "play none none reset",
      onEnter: () => {
        mainTl
          .to(additionalPlanets, {
            scale: 1,
            duration: 1,
            ease: "Power4.easeOut",
          })
          .to(
            mainCircle,
            {
              scale: 1,
              duration: 1,
              ease: "Power4.easeOut",
            },
            "-=50%"
          )
          .to(
            circles,
            {
              scale: 1,
              duration: 1.5,
              ease: "Power2.easeOut",
            },
            "-=20%"
          )
          .to(
            label,
            {
              y: "-0.5px",
              duration: 0.6,
              ease: "linear",
              textContent: "Join Boldare Team",
            },
            ">-=0.1"
          )
          .to(
            heading,
            {
              y: "-0.5px",
              duration: 0.6,
              ease: "linear",
            },
            ">-=0.1"
          )
          .to(
            actionButton,
            {
              y: "-0.5px",
              duration: 0.6,
              ease: "linear",
            },
            "<+=50%"
          );
      },
    });
  }, []);

  return (
    <section
      className={"section-join section-box"}
      data-color="white"
      ref={introWrapper}
    >
      <div className="padding__container">
        <div className={"section-join__content"}>
          <SectionHeader
            additionalClass={"section-join__heading"}
            color={"mine-shaft"}
            headingType={2}
            label={"Join Boldare Team"}
            labelColor={"mine-shaft"}
            size={"large"}
            centered
          >
            So... Do you want to build products that give real value to users?
          </SectionHeader>
          <Link additionalClass={'career-link'} to={"https://boldare.com/career/"}>
            <Button color={"yellow"}>Discover our career site</Button>
          </Link>
        </div>
        <div className={"additional-planet__container"}>
          <span className="additional-planet additional-planet--large"></span>
          <span className="additional-planet additional-planet--small"></span>
        </div>
        <div className={"section-join__background"}>
          <span className={"circle--main"} />
          <div className={"box__container"}>
            <div className={"box box--bigger"}>
              <img
                className={"planet planet--1"}
                src={planet1}
                alt={"Boldare Products join us"}
              />
              <img
                className={"planet planet--5"}
                src={planet5}
                alt={"Boldare Products join us"}
              />
            </div>
            <div className={"box"}>
              <img
                className={"planet planet--2"}
                src={planet2}
                alt={"Boldare Products join us"}
              />
              <img
                className={"planet planet--3"}
                src={planet3}
                alt={"Boldare Products join us"}
              />
              <img
                className={"planet planet--4"}
                src={planet4}
                alt={"Boldare Products join us"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
