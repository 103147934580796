import React from "react";
import { Link } from "react-scroll";

type SmoothScrollLinkProps = {
  className?: string;
  id?: string;
  href: string;
  onClick?: () => void;
  duration?: number;
  offset?: number;
  to: string;
  children?: React.ReactNode;
};

const DEFAULT_SCROLL_DURATION = 600;
const DEFAULT_SCROLL_OFFSET = -100;

const SmoothScrollLink = ({
  className,
  id,
  duration,
  offset,
  children,
  to,
  href,
  onClick,
}: SmoothScrollLinkProps) => {
  const linkOffset = offset ? offset : DEFAULT_SCROLL_OFFSET;
  const linkDuration = duration ? duration : DEFAULT_SCROLL_DURATION;

  return (
    <Link
      id={id}
      href={href}
      to={to}
      offset={linkOffset}
      duration={linkDuration}
      className={className}
      smooth
      onClick={() => onClick && onClick()}
    >
      {children}
    </Link>
  );
};

export default SmoothScrollLink;
