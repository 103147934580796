import React from "react";
import { classNames } from "helpers";
import icons from "./icons-set.svg";
import "./IconSVG.scss";

type IconSVGProps = {
  size?: string;
  color: string;
  background?: string;
  name: string;
  additionalClass?: string;
};

const IconSVG = ({
  additionalClass = "",
  background = "",
  size = "medium",
  name = "",
  color = "black",
}: IconSVGProps) => (
  <div
    className={classNames(
      "icon-svg__wrapper",
      {
        [`icon-svg--background-${background}`]: background,
        [`icon-svg__wrapper--${size}`]: size,
      },
      additionalClass
    )}
  >
    <svg
      className={classNames("icon-svg", {
        [`icon-svg-${name}`]: name,
        [`icon-svg--color-${color}`]: color,
      })}
    >
      <use xlinkHref={`${icons}#icon-${name}`} />
    </svg>
  </div>
);

export default IconSVG;
